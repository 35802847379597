// npm
import styled from "styled-components";

// css
import { css } from "css";

type CardProps = {
  isSelected?: boolean;
  title: string;
  subTitle?: string;
  onClick?: () => void;
  size?: "small" | "medium" | "large";
  footer?: React.ReactNode;
};

export const Card = ({ isSelected, title, subTitle, onClick, size = "medium", footer }: CardProps) => {
  return (
    <StyledCard onClick={onClick} $isSelected={isSelected} $size={size} $hasFooter={!!footer}>
      <>
        <CardTitle>{title}</CardTitle>
        {subTitle && <CardSubTitle>{subTitle}</CardSubTitle>}
      </>
      {footer && footer}
    </StyledCard>
  );
};

const CardTitle = styled.h2`
  ${css.pureText}

  color: ${({ theme }) => theme.colors.lining};

  font-size: 18px;
  font-weight: 600;
  line-height: 29px;
  margin-left: 8px;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const CardSubTitle = styled.p`
  ${css.pureText}

  color: ${({ theme }) => theme.colors.lining};

  font-size: 14px;
  line-height: 150%;
  margin-left: 8px;

  @media (max-width: 768px) {
    font-size: 10px;
  }
`;

type StyledCardProps = {
  $isSelected?: boolean;
  $size?: "small" | "medium" | "large";
  $hasFooter?: boolean;
};

const StyledCard = styled.div<StyledCardProps>`
  ${css.flex({ j: "fs", aI: "fs", dir: "col", gap: 20 })}
  ${({ $hasFooter }) => $hasFooter && "justify-content: space-between;"}
  ${css.pad.xl}
  ${css.point}

  height: 200px;
  background: linear-gradient(0deg, #2a2d36, #222431);
  border-radius: 20px;

  ${({ $size }) => {
    if ($size === "small") {
      return `
        min-width: 250px;
        width: 250px;
      `;
    }
    if ($size === "medium") {
      return `
        min-width: 300px;
        width: 300px;
      `;
    }
  }}

  ${({ $isSelected }) => {
    return `
      box-shadow: ${
        $isSelected
          ? `
    5px 5px 20px 5px #FCC85255,
    -5px -5px 20px 5px #FF3D9F55, 
    15px 15px 30px 5px rgba(14, 14, 16, 0.7),
    -5px -5px 30px 20px rgba(227, 227, 227, 0.03);
`
          : `
    15px 15px 30px 5px rgba(14, 14, 16, 0.7),
    -5px -5px 30px 20px rgba(227, 227, 227, 0.03);
  `
      };
    `;
  }}

  &:hover {
    border: 1px solid #fcc85255;
    padding: 31px;
  }
`;
