// npm
import React from "react";
import styled from "styled-components";

// css
import { css } from "css";

type InputProps = {
  wFull?: boolean;
  children?: React.ReactNode;
  type?: "number" | "password" | "text";
  size?: "md" | "lg";
  placeholder?: string;
  value?: any;
  error?: string;
  onChange?: (e: any) => void;
};

export const Input = ({ wFull, type = "text", placeholder, value, onChange, size = "md", error }: InputProps) => {
  return (
    <Wrapper wFull={wFull} $error={error}>
      <StyledInput
        wFull={wFull}
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        $size={size}
        $error={error}
      />
      {error && <HelperText>{error}</HelperText>}
    </Wrapper>
  );
};

type WrapperProps = {
  wFull?: boolean;
  $error?: string;
  children: React.ReactNode;
};

const Wrapper = styled.div<WrapperProps>`
  ${css.flex({ dir: "col", j: "c", aI: "c" })}

  width: ${({ wFull }) => (wFull ? "100%" : "250px")};
  ${({ $error }: WrapperProps) => !$error && `padding-bottom: 34px;`}
`;

const HelperText = styled.p`
  ${css.textLeft}

  color: ${({ theme }) => theme.colors.error};

  font-size: 16px;
  font-weight: 600;
  margin-top: 10px;
  margin-bottom: 0;
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
  line-height: 1.5;
  word-wrap: break-word;
  word-break: break-all;
  white-space: pre-line;
`;

type StyledInputProps = {
  wFull?: boolean;
  placeholder?: string;
  type: string;
  value?: any;
  onChange?: (e: any) => void;
  $size: string;
  $error?: string;
};

const StyledInput = styled.input<StyledInputProps>`
  ${({ theme }) => theme.borders.md}
  ${css.pureText}

  width: ${({ wFull }) => (wFull ? "100%" : "250px")};
  height: 30px;
  font-size: 18px;

  background-color: transparent;
  padding: 0 20px;

  ${({ $size }) => $size === "lg" && `height: 70px; width: 400px; font-size: 24px;`}

  &:focus {
    ${({ theme }) => theme.colors.glow.md}

    outline: none;
    border: 2px solid transparent;
  }

  ${({ $error }) => $error && css.errorGlow}
`;
