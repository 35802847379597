// npm
import styled from "styled-components";
import { MoonLoader } from "react-spinners";

// hooks
import { useReaderStore } from "store/readerStore";

// css
import { css } from "css";

// types
type TopLineProps = {
  tabSelected: string;
  setTabSelected: (tab: string) => void;
};

export const TopLine = ({ tabSelected, setTabSelected }: TopLineProps) => {
  // hooks
  const {
    reader: {
      selectedPage: { pageType },
    },
  } = useReaderStore();

  return (
    <ReaderTopLine>
      <TabSelect>
        <TabOption onClick={() => setTabSelected("text")} $tabSelected={tabSelected} $tabLabel={"text"} $side={"l"}>
          Text
        </TabOption>
        <TabOption
          onClick={() => setTabSelected("lessons")}
          $tabSelected={tabSelected}
          $tabLabel={"lessons"}
          $side={"r"}
        >
          {pageType ? pageType : <MoonLoader color={"#fcc852"} size={18} />}
        </TabOption>
      </TabSelect>
    </ReaderTopLine>
  );
};

const ReaderTopLine = styled.div`
  ${css.padX.l}
`;

const TabSelect = styled.div`
  ${css.flex({ j: "fs", aI: "c" })}

  @media (max-width: 768px) {
    gap: 10px;
    margin-top: 10px;
  }
`;

type TabOptionProps = {
  $tabSelected: string;
  $tabLabel: string;
  $side: "r" | "l" | "c";
};

const TabOption = styled.div<TabOptionProps>`
  ${css.pureText}

  font-size: 20px;
  font-weight: 600;
  padding: 5px 10px;
  color: white;
  border: 1px solid black;

  @media (max-width: 768px) {
    font-size: 16px;
  }

  ${({ $side }: TabOptionProps) => {
    if ($side === "r") return `border-top-right-radius: 5px; border-bottom-right-radius: 5px;`;
    if ($side === "l") return `border-top-left-radius: 5px; border-bottom-left-radius: 5px;`;
  }}

  ${({ $tabSelected, $tabLabel }: TabOptionProps) =>
    $tabSelected === $tabLabel
      ? `background: linear-gradient(90deg, #fcc852, #ff3d9f);`
      : `background: linear-gradient(0deg, #2a2d36, #222431);`}
`;
