// npm
import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { MoonLoader } from "react-spinners";

// hooks
import { useAppStore } from "store/index";
import { useUIStore } from "store/uiStore";
import { useReaderStore } from "store/readerStore";

// components
import { Flex } from "components/utility";
import { Button } from "components/shared";

// css
import { css } from "css";

// types
import { Token } from "models/token";

export const Page = () => {
  // hooks
  const navigate = useNavigate();
  const { userWords, targetLang } = useAppStore();
  const { isMobile, isLoadingReading } = useUIStore();
  const { reader, setWord, selectedIndex, setSelectedIndex } = useReaderStore();

  // refs
  const readerContainerRef = useRef<HTMLDivElement>(null);

  // methods
  const speak = (text: string) => {
    if (text !== "") {
      const utterance = new SpeechSynthesisUtterance(text);
      if (targetLang === "ru") utterance.lang = "ru-RU";
      if (targetLang === "de") utterance.lang = "de-DE";
      if (targetLang === "fr") utterance.lang = "fr-FR";
      if (targetLang === "es") utterance.lang = "es-ES";
      if (targetLang === "ko") utterance.lang = "ko-KO";
      if (targetLang === "it") utterance.lang = "it-IT";
      if (targetLang === "hu") utterance.lang = "hu-HU";

      speechSynthesis.speak(utterance);
    }
  };

  const clickWord = (word: Token, i: number) => {
    if (selectedIndex && selectedIndex === i) {
      setSelectedIndex(null);
      setWord({} as Token);
      //setTranslation("");
      //setNotes("");
    } else {
      setSelectedIndex(i);
      setWord(word);
      speak(word.display);
      //getNotes(word.lowerCase);
      /*
      axios
        .post(baseApi + "/translation/getter", {
          lowerCase: word.lowerCase,
          targetLanguage: targetLang,
          homeLanguage: homeLang,
        })
        .then((res) => {
          console.log("HERERERERE", res.data);
          setTranslation(res.data.translatesTo);
          // let's find the correct token in the processedPage and add the translation to it
          const newProcessedPage = processedPageRef.current.map((p) => {
            if (p.lowerCase === word.lowerCase) {
              return {
                ...p,
                translation: res.data.translatesTo,
              };
            }
            return p;
          });
        })
        .catch((err) => {
          console.log(err);
        });
        */
    }
  };

  // markups
  const wordsMarkup = () =>
    reader.tokens.map((word: Token, i: number) => {
      const key = word.display + i;
      if (!word.isWord) {
        if (word.display === " ") return <div key={key} style={{ width: "4px" }} />;
        if (word.display === "\n") return <div key={key} style={{ width: "100%", height: "4px" }} />;
        return <Punctuation key={word.display + i}>{word.display}</Punctuation>;
      }

      return (
        <Word
          key={key}
          onClick={() => clickWord(word, i)}
          selected={selectedIndex}
          $isLevels={true}
          $index={i}
          $level={
            userWords.find((w) => {
              return w.lowercase === word.lowercase;
            })?.level || 0
          }
        >
          {word.display}
        </Word>
      );
    });

  return (
    <>
      <PageWordsContainer ref={readerContainerRef}>
        <Flex hFull wFull align={"flex-start"} content={"flex-start"} justify={"start"} wrap gap={"8px 0px"}>
          {isLoadingReading ? (
            <Flex hFull wFull>
              <MoonLoader color={"#fcc852"} loading={isLoadingReading} />
            </Flex>
          ) : (
            <>{wordsMarkup()}</>
          )}
        </Flex>
      </PageWordsContainer>
      <FinishedWrapper>
        <Flex>
          <Button size={isMobile ? "small" : "medium"} onClick={() => navigate("/")}>
            Finished
          </Button>
        </Flex>
      </FinishedWrapper>
    </>
  );
};

const PageWordsContainer = styled.div`
  ${css.scroll}

  max-width: 800px;
  width: 800px;
  overflow-y: auto;
  padding: 5px 20px;
  height: 85%;

  background: linear-gradient(0deg, #2a2d36, #222431);
  border-radius: 20px;

  @media (max-width: 1268px) {
    width: 500px;
    max-width: 500px;
  }

  @media (max-width: 940px) {
    width: 400px;
    max-width: 400px;
  }
  @media (max-width: 840px) {
    width: 300px;
    max-width: 300px;
  }
  @media (max-width: 768px) {
    width: 100%;
    max-width: 100%;
  }
`;

const FinishedWrapper = styled.div`
  width: 100%;
`;

const Punctuation = styled.div`
  ${css.pureText}

  font-size: 20px;
  font-weight: 600;
  border-radius: 5px;

  @media (max-width: 1268px) {
    font-size: 18px;
  }

  @media (max-width: 940px) {
    font-size: 16px;
  }

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

type WordProps = {
  selected: number | null;
  $isLevels: boolean;
  $index: number;
  $level: number;
};

const Word = styled.div<WordProps>`
  ${css.pureText}
  ${css.point}

  font-size: 20px;
  font-weight: 600;
  border-radius: 5px;
  padding: 0px 2px;

  color: ${({ $level, $isLevels }: WordProps) => {
    if ($level === 0 && $isLevels) return "rgba(145, 165, 255, 1)";
    if ($level === 1 && $isLevels) return "rgba(255, 117, 150, 1)";
    if ($level === 2 && $isLevels) return "rgba(255, 211, 124, 1)";
    if ($level === 3 && $isLevels) return "rgba(26, 188, 156, 1)";
    if ($level === 4 && $isLevels) return "rgba(255, 255, 255, 0.85)";
  }};

  ${({ selected, $index, $level }: WordProps) =>
    selected === $index && $level === 4
      ? `box-shadow: 2px 2px 5px 5px #fcc85235, -2px -2px 5px 5px #ff3d9f35;`
      : selected === $index && `box-shadow: 0 0 2px 2px rgba(235, 235, 235, 0.5);`}

  @media (max-width: 1268px) {
    font-size: 18px;
  }

  @media (max-width: 940px) {
    font-size: 16px;
  }

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;
