// npm
import { useEffect, useState, useRef } from "react";
import styled from "styled-components";

// hooks
import { useAppStore } from "store/index";
import { useQuery } from "hooks/query";
import { useKeyboard } from "hooks/keyboard";
import { useReaderStore } from "store/readerStore";

// css
import { css } from "css";

// types
import { Token } from "models/token";

const levels = [1, 2, 3, 4];

export const Levels = () => {
  // hooks
  const { user, userWords, setUserWords } = useAppStore();
  const { saveUserWord } = useQuery();
  const { handleLevelKeys } = useKeyboard();
  const { word, selectedIndex, reader } = useReaderStore();

  // state
  const [isWordLevelLoading, setIsWordLevelLoading] = useState(false);

  // refs
  const userRef = useRef(user);
  const wordRef = useRef(word);
  const userWordsRef = useRef(userWords);
  const selectedIndexRef = useRef(selectedIndex);
  const readerRef = useRef(reader);

  // methods
  const setWordLevel = async (word: Token, level: number) => {
    if (!userRef.current?.token) return;
    setIsWordLevelLoading(true);
    const userWords = userWordsRef.current;
    let notFound = false;

    if (!word) return;
    const newWords = userWords.map((w) => {
      if (w.lowercase === word.lowercase) {
        return { ...w, level };
      } else {
        notFound = true;
        return w;
      }
    });

    if (notFound || userWords.length === 0) newWords.push({ lowercase: word.lowercase, level });

    const callback = () => setUserWords(newWords);
    await saveUserWord(word.lowercase, level, callback);
    setIsWordLevelLoading(false);
  };

  const handleKeyboard = (e: KeyboardEvent) => {
    handleLevelKeys(e, wordRef.current, setWordLevel, selectedIndexRef.current, readerRef.current.tokens);
  };

  // watchers
  useEffect(() => {
    userRef.current = user;
    wordRef.current = word;
    userWordsRef.current = userWords;
    selectedIndexRef.current = selectedIndex;
    readerRef.current = reader;
  }, [user, word, userWords, selectedIndex, reader]);

  useEffect(() => {
    document.addEventListener("keydown", handleKeyboard);
    return () => {
      document.removeEventListener("keydown", handleKeyboard);
    };
  }, []);

  return (
    <ButtonContainer>
      {!user?.token && <div style={{ fontSize: "10px" }}>Log in or create an account to save word progress</div>}
      <ButtonWrapper>
        {levels.map((level) => (
          <LevelButton
            key={level}
            $isSelected={userWords.filter((x) => x.lowercase === word.lowercase)[0]?.level === level}
            $isDisabled={!user?.token}
            $level={level}
            onClick={() => word && setWordLevel(word, level)}
          >
            {isWordLevelLoading ? <Grey>{level}</Grey> : level}
          </LevelButton>
        ))}
      </ButtonWrapper>
    </ButtonContainer>
  );
};

const ButtonContainer = styled.div`
  ${css.flex({ j: "c", aI: "c", dir: "col" })}

  width: 100%;
  min-width: 362px;
  min-height: 90px;

  box-shadow:
    15px 15px 30px 5px rgba(14, 14, 16, 0.7),
    -5px -5px 30px 20px rgba(227, 227, 227, 0.03);
  background: linear-gradient(0deg, #2a2d36, #222431);
  border-radius: 20px;

  @media (max-width: 768px) {
    min-height: 60px;
    width: 90%;
    min-width: 90%;
  }
`;

const ButtonWrapper = styled.div`
  ${css.flex({ j: "se", aI: "c" })}

  width: 100%;
`;

type LevelButtonProps = {
  $isSelected: boolean;
  $isDisabled: boolean;
  $level: number;
};

const LevelButton = styled.button<LevelButtonProps>`
  ${css.flex({ j: "c", aI: "c" })}
  ${css.pureText}

  height: 50px;
  width: 50px;
  padding: 10px 12px;
  font-size: 24px;
  font-weight: 600;
  border-radius: 5px;

  ${({ $isSelected, $level }: LevelButtonProps) => {
    if ($isSelected) {
      if ($level === 0)
        return `box-shadow: 5px 5px 20px 0px rgba(145, 165, 255, 0.4), -5px -5px 20px 0px rgba(145, 165, 255, 0.4);`;
      if ($level === 1)
        return `box-shadow: 5px 5px 20px 0px rgba(255, 117, 150, 0.4), -5px -5px 20px 0px rgba(255, 117, 150, 0.4);`;
      if ($level === 2)
        return `box-shadow: 5px 5px 20px 0px rgba(255, 211, 124, 0.4), -5px -5px 20px 0px rgba(255, 211, 124, 0.4);`;
      if ($level === 3)
        return `box-shadow:5px 5px 20px 0px rgba(26, 188, 156, 0.4), -5px -5px 20px 0px rgba(26, 188, 156, 0.4);`;
      if ($level === 4) return `box-shadow: 5px 5px 20px 0px #fcc85255, -5px -5px 20px 0px #ff3d9f55;`;
    }
  }}

  ${({ $isDisabled }: LevelButtonProps) =>
    !$isDisabled
      ? `
          border: 1px solid black;
          background: #2a2d36;
          color: white;
          cursor: pointer;
        `
      : `
          border: 1px solid #343434;
          background: #282828;
          color: #343434;
          box-shadow: none;
        `}


  @media (max-width: 768px) {
    height: 35px;
    width: 35px;
    padding: 5px 6px;
    font-size: 16px;
  }
`;

const Grey = styled.span`
  color: gray;
`;
