// store
import { useReaderStore } from "store/readerStore";

// models
import { Token } from "models/token";

export const useKeyboard = () => {
  const { setWord, setSelectedIndex } = useReaderStore();

  const handleLevelKeys = (
    e: KeyboardEvent,
    word: Token,
    setWordLevel: (word: Token, level: number) => void,
    selectedIndex: number | null,
    tokens: Token[],
  ) => {
    if (e.key === "0" || e.key === "y") setWordLevel(word || "", 0);
    if (e.key === "1" || e.key === "u") setWordLevel(word || "", 1);
    if (e.key === "2" || e.key === "i") setWordLevel(word || "", 2);
    if (e.key === "3" || e.key === "o") setWordLevel(word || "", 3);
    if (e.key === "4" || e.key === "p") setWordLevel(word || "", 4);

    if (e.key === "l") {
      let nextIndex = selectedIndex ? selectedIndex + 1 : 1;
      let nextWord: Token;
      let found = false;

      while (found === false) {
        if (nextIndex > tokens.length - 1) return;
        nextWord = tokens[nextIndex];

        if (nextWord.isWord) found = true;
        else nextIndex++;
      }

      const tempWord = tokens[nextIndex];
      setSelectedIndex(nextIndex);
      setWord(tempWord);
    }

    if (e.key === "h") {
      let lastIndex = selectedIndex ? selectedIndex - 1 : 1;
      let lastWord: Token;
      let found = false;

      while (found === false) {
        if (lastIndex < 0) return;
        lastWord = tokens[lastIndex];
        if (lastWord.isWord) found = true;
        else lastIndex--;
      }

      const tempWord = tokens[lastIndex];
      setSelectedIndex(lastIndex);
      setWord(tempWord);
    }
  };

  return { handleLevelKeys };
};
