// npm
import styled from "styled-components";

// hooks
import { useUIStore } from "store/uiStore";

// components
import { Flex } from "components/utility";

// css
import { css } from "css";

// types
import { ShortScreen } from "types/styled-components";

export const LoginCopy = () => {
  // hooks
  const { isShortScreen } = useUIStore();

  const copy = [
    "Log in to your account to continue learning a new language. If you don't have an account, you can sign up for free.",
    "You can save your progress with each word and phrase you learn. You can also track your progress and see how many words you have learned.",
    "Our app is designed to help you learn a new language in the most efficient way possible. We use the latest research in language learning to help you learn a new language, fast.",
  ];

  return (
    <Container>
      <Flex hFull col justify={"space-evenly"} gap={"10px"}>
        {copy.map((c, i) => (
          <Text key={i} $isShortScreen={isShortScreen}>
            {c}
          </Text>
        ))}
      </Flex>
    </Container>
  );
};

const Container = styled.div`
  height: 80%;
  padding: 4px;
`;

const Text = styled.div<ShortScreen>`
  ${css.pureText}
  ${css.copy}

  max-width: 700px;
  margin: 0 50px;

  font-size: ${({ $isShortScreen }: ShortScreen) => ($isShortScreen ? "16px" : "24px")};

  @media (max-width: 1268px) {
    ${({ $isShortScreen }: ShortScreen) => (!$isShortScreen ? "font-size: 20px;" : "")}
  }

  @media (max-width: 968px) {
    font-size: 16px;
  }
`;
