// npm
import { useState } from "react";
import styled from "styled-components";

// components
import { TopLine } from "./TopLine";
import { Page } from "./Page";

// css
import { css } from "css";

// types
import { PageList } from "./PageList";

export const Reader = () => {
  // state
  const [tabSelected, setTabSelected] = useState("text");

  return (
    <PageContainer $tabSelected={tabSelected}>
      <TopLine tabSelected={tabSelected} setTabSelected={setTabSelected} />
      {tabSelected === "text" ? <Page /> : <PageList setTabSelected={setTabSelected} />}
    </PageContainer>
  );
};

type PageContainerProps = {
  $tabSelected: string;
};

const PageContainer = styled.div<PageContainerProps>`
  ${css.flex({ j: "fs", dir: "col", gap: 30 })}
  ${({ $tabSelected }) => $tabSelected === "text" && `justify-content: space-between; gap: 10px;`}

  max-width: 820px;

  height: 80%;
  background: linear-gradient(0deg, #2a2d36, #222431);
  border-radius: 20px;
  box-shadow:
    15px 15px 30px 5px rgba(14, 14, 16, 0.7),
    -5px -5px 30px 20px rgba(227, 227, 227, 0.03);
  padding: 0px 0px 10px 0px;

  @media (max-width: 768px) {
    width: 90%;
    max-width: 90%;
    margin-top: 10px;
    max-height: 60%;
    gap: 5px;
    padding: 5px 0px 5px 0px;
  }
`;
