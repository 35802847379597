// npm
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";

// hooks
import { useAppStore } from "store";
import { useUIStore } from "store/uiStore";
import { useQuery } from "hooks/query";

// components
import { Flex } from "components/utility";
import { Reader, Levels } from "components/reading";

// css
import { css } from "css";

export const ReadingView = () => {
  // hooks
  const { id } = useParams();
  const { user } = useAppStore();
  const { isMobile, setIsLoadingReading } = useUIStore();
  const { getReader, getUserWords } = useQuery();

  const reset = async (id: string) => {
    setIsLoadingReading(true);
    await getReader(id, 0);
    user?.token && (await getUserWords());
    setIsLoadingReading(false);
  };

  // lifecycle
  useEffect(() => {
    if (id) reset(id);
  }, [id]);

  return (
    <Flex hFull col={isMobile}>
      <Reader />
      <InfoSection>
        {isMobile && <Levels />}
        <WordInfoCard></WordInfoCard>
        {!isMobile && <Levels />}
        <Flex>
          <ToggleSwitch onClick={() => {}} $isActive={true}>
            <SwitchButton $isActive={true}>Levels</SwitchButton>
          </ToggleSwitch>
        </Flex>
      </InfoSection>
    </Flex>
  );
};

const InfoSection = styled.div`
  ${css.flex({ j: "c", aI: "c", dir: "col", gap: 40 })}

  width: 25%;
  min-width: 362px;
  height: 100%;

  @media (max-width: 768px) {
    gap: 10px;
    width: 90%;
    min-width: 90%;
    height: auto;
    margin-bottom: 20px;
  }
`;

const WordInfoCard = styled.div`
  ${css.flex({ dir: "col", gap: 20 })}
  ${css.pureText}

  width: 100%;
  padding: 20px;
  box-shadow:
    15px 15px 30px 5px rgba(14, 14, 16, 0.7),
    -5px -5px 30px 20px rgba(227, 227, 227, 0.03);
  background: linear-gradient(0deg, #2a2d36, #222431);
  border-radius: 20px;
  min-height: 136px;

  @media (max-width: 768px) {
    padding: 10px 20px;
    gap: 10px;
    width: 90%;
    min-height: 82px;
  }
`;

type ToggleSwitchProps = {
  $isActive: boolean;
};

const ToggleSwitch = styled.div<ToggleSwitchProps>`
  ${css.flex({ j: "fs", aI: "c" })}
  ${({ $isActive }) => $isActive && "justify-content: flex-end;"}

  height: 50px;
  width: 240px;
  padding: 10px 0px;
  background-color: #2a2c3a;
  color: white;
  font-size: 24px;
  font-weight: 600;

  border-top: 2px solid #191a1d;
  border-bottom: 2px solid #373c42;
  background-image: linear-gradient(#191a1d, #373c42), linear-gradient(#191a1d, #373c42);
  background-size: 2px 100%;
  background-position:
    0 0,
    100% 0;
  background-repeat: no-repeat;
  border-radius: 15px;

  @media (max-width: 768px) {
    height: 35px;
    width: 120px;
  }
`;

type SwitchButtonProps = {
  $isActive: boolean;
};

const SwitchButton = styled.div<SwitchButtonProps>`
  ${css.flex({ j: "c", aI: "c" })}
  ${css.pureText}
  ${css.point}

  height: 50px;
  width: 130px;
  padding: 10px 12px;
  ${({ $isActive }: SwitchButtonProps) =>
    $isActive ? `background: linear-gradient(90deg, #fcc852, #ff3d9f);` : `background: #2a2d36;`}

  font-size: 24px;
  font-weight: 600;
  border: 1px solid black;
  border-radius: 15px;

  @media (max-width: 768px) {
    height: 35px;
    width: 65px;
    padding: 5px 6px;
    font-size: 14px;
    border-radius: 10px;
  }
`;
