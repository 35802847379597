// hooks
import { useAppStore } from "store";

// types
import { UILangs } from "./uiLangsTypes";

export const useUILangs = (): { langs: UILangs; getTranslation: (...lookups: string[]) => string } => {
  const { homeLang } = useAppStore();

  const getTranslation = (...lookups: string[]): string => {
    let translation = langs[homeLang];
    for (const lookup of lookups) {
      translation = translation[lookup];
    }
    return translation();
  };

  const langs = {
    en: {
      startPage: {
        intro1: () =>
          "Sign up today and embark on a journey to learn a new language! It's completely free. With our app, you can dive into a world of new words and phrases, making learning easy and enjoyable.",
        intro2: () =>
          "Keep tabs on your journey with every word and phrase you acquire. Utilize our feature to save your progress, allowing you to reflect on how far you've come. Discover the satisfaction of mastering new vocabulary and phrases as you navigate through our platform.",
        intro3: () =>
          "Our app is designed to help you learn a new language in the most efficient way possible. We use the latest research in language learning to help you learn a new language, fast.",
        signUp: () => "Sign up",
        username: () => "Username",
        email: () => "Email",
        password: () => "Password",
        confirmPw: () => "Confirm password",
        logIn: () => "Log in",
        welcomeBack: () => "Welcome back!",
        registerFree: () => "Register for free",
      },
      profileSettings: {
        profile: () => "Profile",
        editProfile: () => "Edit profile",
        editAvatar: () => "Edit Profile Picture",
        deleteProfile: () => "Delete profile",
        logOut: () => "Log out",
        settings: () => "Settings",
        memberSince: () => "Member since",
        displayLang: () => "Display Language",
        done: () => "Done",
        save: () => "Save",
        notif: () => "Notifications",
      },
      youtube: {
        // TODO: () =>add link here
        kindlyNote: () =>
          'Kindly note that LangFire is currently in its alpha phase, and its functionality may not meet all expectations. Additionally, please be aware that transcripts from YouTube might not always be accessible, accurate, or complete. If you encounter any issues, we encourage you to report them using the "Report Problem" feature. Your feedback is highly appreciated.',
        reportProb: () => "Report Problem",
        myVideos: () => "My Videos",
        newVideo: () => "New Video",
        beingProcessed: () => "Being processed",
        videosQueue: () => "Videos in the queue",
      },
      wordCards: {
        flashCards: () => "Flashcards",
        nextCard: () => "Next Card Ready",
        now: () => "Now",
        today: () => "Today",
        tomorrow: () => "Tomorrow",
        nextWeek: () => "Next week",
        soon: () => "Soon",
        total: () => "Total",
        level: () => "Level",
        add20: () => "Add 20 Cards",
        reveal: () => "Reveal",
        addNote: () => "Add a note",
        seen: () => "Seen",
        familiar: () => "Familiar",
        good: () => "Good",
        known: () => "Known",
      },
      browseContent: {
        menu: () => "Menu",
        home: () => "Home",
        originalCont: () => "Original content",
        designOwn: () =>
          "Design your own learning journey. Create custom lessons that perfectly fit your unique learning preferences for maximum effectiveness.",
        langContent: () => "LangFire content",
        myContent: () => "My content",
        books: () => "Books",
        kidsBooks: () => "Children's books",
        audioBooks: () => "Audio books",
        wiki: () => "Wikipedia",
        discover: () => "Discover",
        search: () => "Search",
        faves: () => "Favourites",
        uploadContent: () => "Upload reading content",
        addText: () => "Add text here...",
        upload: () => "Upload",
        chapter: () => "Chapter",
        chapterTitle: () => "Chapter title",
        description: () => "Description",
      },
      contactFAQ: {
        rightsReserved: () => "©2024Predicate Software Solutions Ltd. All rights reserved.",
        aboutUs: () => "About us",
        help: () => "Help",
        giveFeedback: () => "Give feedback",
        submitFeedback: () => "Submit feedback",
        priccing: () => "Pricing",
        contact: () => "Contact",
      },
    },

    de: {
      startPage: {
        intro1: () =>
          "Registrieren Sie sich noch heute und machen Sie sich auf den Weg, eine neue Sprache zu lernen! Es ist völlig kostenlos. Mit unserer App können Sie in eine Welt neuer Wörter und Phrasen eintauchen. und das Lernen einfach und unterhaltsam gestalten.",
        intro2: () =>
          "Behalten Sie Ihren Fortschritt bei jeder erlernten Vokabel und Wendung im Blick. Nutzen Sie unsere Funktion zum Speichern Ihres Fortschritts, um zu sehen, wie weit Sie gekommen sind. Entdecken Sie die Zufriedenheit, neue Vokabeln und Wendungen zu beherrschen, während Sie durch unsere Plattform navigieren.",
        intro3: () =>
          "Unsere App ist darauf ausgelegt, Ihnen auf effizienteste Weise beim Erlernen einer neuen Sprache zu helfen. Wir nutzen die neuesten Erkenntnisse im Bereich des Sprachenlernens, um Ihnen zu helfen, eine neue Sprache schnell zu erlernen.",
        signUp: () => "Registrieren",
        username: () => "Benutzername",
        email: () => "E-Mail",
        password: () => "Passwort",
        confirmPw: () => "Passwort bestätigen",
        logIn: () => "Anmelden",
        welcomeBack: () => "Willkommen zurück!",
        registerFree: () => "Kostenlos registrieren",
      },
      profileSettings: {
        profile: () => "Profil",
        editProfile: () => "Profil bearbeiten",
        editAvatar: () => "Profilbild bearbeiten",
        deleteProfile: () => "Profil löschen",
        logOut: () => "Ausloggen",
        settings: () => "Einstellungen",
        memberSince: () => "Mitglied seit",
        displayLang: () => "App-Sprache",
        done: () => "Fertig",
        save: () => "Speichern",
        notif: () => "Benachrichtigungen",
      },
      youtube: {
        // TODO: () =>add link here
        kindlyNote: () =>
          'Bitte beachten Sie, dass LangFire sich derzeit in der Alpha-Phase befindet und die Funktionalität möglicherweise nicht allen Erwartungen entspricht. Bitte beachten Sie auch, dass Transkripte von YouTube nicht immer zugänglich, genau oder vollständig sein können. Wenn Sie auf Probleme stoßen, bitten wir Sie darum, diese über die Funktion "Problem melden" zu melden. Ihr Feedback wird sehr geschätzt.',
        reportProb: () => "Problem melden",
        myVideos: () => "Meine Videos",
        newVideo: () => "Neues Video",
        beingProcessed: () => "Wird verarbeitet",
        videosQueue: () => "Videos in der Warteschlange",
      },
      wordCards: {
        flashCards: () => "Karteikarten",
        nextCard: () => "Nächste Karte bereit",
        now: () => "Jetzt",
        today: () => "Heute",
        tomorrow: () => "Morgen",
        nextWeek: () => "Nächste Woche",
        soon: () => "Bald",
        total: () => "Gesamt",
        level: () => "Stufe",
        add20: () => "20 Karten hinzufügen",
        reveal: () => "Antwort anzeigen",
        addNote: () => "Eine Notiz hinzufügen",
        seen: () => "Gesehen",
        familiar: () => "Vertraut",
        good: () => "Gut",
        known: () => "Bekannt",
      },
      browseContent: {
        menu: () => "Menü",
        home: () => "Startseite",
        originalCont: () => "Originalinhalte",
        designOwn: () =>
          "Gestalte deine eigene Lernreise. Erstelle maßgeschneiderte Lektionen, die perfekt auf deine einzigartigen Lernpräferenzen zugeschnitten sind, für maximale Wirkung.",
        langContent: () => "LangFire Inhalte",
        myContent: () => "Meine Inhalte",
        books: () => "Bücher",
        kidsBooks: () => "Kinderbücher",
        audioBooks: () => "Hörbücher",
        wiki: () => "Wikipedia",
        discover: () => "Entdecken",
        search: () => "Suchen",
        faves: () => "Favoriten",
        uploadContent: () => "Leseinhalt hochladen",
        addText: () => "Fügen Sie hier Text ein...",
        upload: () => "Hochladen",
        chapter: () => "Kapitel",
        chapterTitle: () => "Kapiteltitel",
        description: () => "Beschreibung",
      },
      contactFAQ: {
        rightsReserved: () => "© 2024 Predicate Software Solutions Ltd. Alle Rechte vorbehalten.",
        aboutUs: () => "Über uns",
        help: () => "Hilfe",
        giveFeedback: () => "Feedback geben",
        submitFeedback: () => "Feedback absenden",
        priccing: () => "Prise",
        contact: () => "Kontakt",
      },
    },

    ru: {
      startPage: {
        intro1: () =>
          "Зарегистрируйтесь сегодня и отправьтесь в путь к изучению нового языка! Это абсолютно бесплатно. С нашим приложением вы сможете погрузиться в мир новых слов и фраз, сделав процесс обучения легким и увлекательным.",
        intro2: () =>
          "Отслеживайте свой путь с каждым изученным словом и фразой. Используйте нашу функцию для сохранения своего прогресса, чтобы видеть, как далеко вы продвинулись. Почувствуйте удовлетворение от овладения новой лексикой и выражениями, путешествуя по нашей платформе.",
        intro3: () =>
          "Наше приложение разработано для того, чтобы помочь вам изучать новый язык наиболее эффективным способом. Мы используем последние научные исследования в области изучения языков, чтобы помочь вам быстро освоить новый язык.",
        signUp: () => "Зарегистрироваться",
        username: () => "Имя пользователя",
        email: () => "Электронная почта",
        password: () => "Пароль",
        confirmPw: () => "Подтвердите пароль",
        logIn: () => "Войти",
        welcomeBack: () => "Добро пожаловать обратно!",
        registerFree: () => "Зарегистрироваться бесплатно",
      },
      profileSettings: {
        profile: () => "Профиль",
        editProfile: () => "Редактировать профиль",
        editAvatar: () => "Изменить аватар",
        deleteProfile: () => "Удалить профиль",
        logOut: () => "Выйти",
        settings: () => "Настройки",
        memberSince: () => "Участник с",
        displayLang: () => "Язык отображения",
        done: () => "Готово",
        save: () => "Сохранить",
        notif: () => "Уведомления",
      },
      youtube: {
        // TODO: () =>add link here
        kindlyNote: () =>
          'Обратите внимание, что LangFire в настоящее время находится в альфа-версии, и его функциональность может не соответствовать всем ожиданиям. Кроме того, имейте в виду, что транскрипты с YouTube не всегда могут быть доступны, точны или полные. Если у вас возникнут проблемы, мы настоятельно рекомендуем вам сообщить о них, используя функцию "Сообщить о проблеме". Ваш отзыв очень важен для нас.',
        reportProb: () => "Сообщить о проблеме",
        myVideos: () => "Мои видео",
        newVideo: () => "Новое видео",
        beingProcessed: () => "Обрабатывается",
        videosQueue: () => "Видео в очереди",
      },
      wordCards: {
        flashCards: () => "Флеш-карточки",
        nextCard: () => "Следующая карточка готова",
        now: () => "Сейчас",
        today: () => "Сегодня",
        tomorrow: () => "Завтра",
        nextWeek: () => "На следующей неделе",
        soon: () => "Скоро",
        total: () => "Всего",
        level: () => "Уровень",
        add20: () => "Добавить 20 карточек",
        reveal: () => "Показать ответ",
        addNote: () => "Добавить заметку",
        seen: () => "Просмотрено",
        familiar: () => "Знакомо",
        good: () => "Хорошо",
        known: () => "Известно",
      },
      browseContent: {
        menu: () => "Меню",
        home: () => "Главная",
        originalCont: () => "Авторский контент",
        designOwn: () =>
          "Создайте индивидуальные уроки, идеально соответствующие вашим предпочтениям, и получите максимальную пользу от обучения.",
        langContent: () => "Контент LangFire",
        myContent: () => "Мой контент",
        books: () => "Книги",
        kidsBooks: () => "Детские книги",
        audioBooks: () => "Аудиокниги",
        wiki: () => "Википедия",
        discover: () => "Возможно интересно",
        search: () => "Поиск",
        faves: () => "Избранное",
        uploadContent: () => "Загрузить материалы для чтения",
        addText: () => "Добавьте текст здесь...",
        upload: () => "Загрузить",
        chapter: () => "Глава",
        chapterTitle: () => "Название главы",
        description: () => "Описание",
      },
      contactFAQ: {
        rightsReserved: () => "©2024 Predicate Software Solutions Ltd. Все права защищены.",
        aboutUs: () => "О нас",
        help: () => "Помощь",
        giveFeedback: () => "Оставить отзыв",
        submitFeedback: () => "Отправить отзыв",
        priccing: () => "Цены",
        contact: () => "Контакты",
      },
    },

    es: {
      startPage: {
        intro1: () =>
          "¡Regístrate hoy mismo y emprende el camino hacia aprender un nuevo idioma! Es completamente gratis. Con nuestra aplicación, puedes sumergirte en un mundo de nuevas palabras y frases, haciendo que aprender sea fácil y divertido.",
        intro2: () =>
          "Mantén un registro de tu avance con cada palabra y frase que adquieras. Utiliza nuestra función para guardar tu progreso, lo que te permitirá reflexionar sobre lo lejos que has llegado. Descubre la satisfacción de dominar nuevo vocabulario y frases mientras navegas por nuestra plataforma.",
        intro3: () =>
          "Nuestra aplicación está diseñada para ayudarte a aprender un nuevo idioma de la manera más eficiente posible. Utilizamos las últimas investigaciones en el aprendizaje de idiomas para ayudarte a aprender un nuevo idioma rápidamente.",
        signUp: () => "Registrarse",
        username: () => "Nombre de usuario",
        email: () => "Correo electrónico",
        password: () => "Contraseña",
        confirmPw: () => "Confirmar contraseña",
        logIn: () => "Iniciar sesión",
        welcomeBack: () => "¡Bienvenido de vuelta!",
        registerFree: () => "Regístrate gratis",
      },

      profileSettings: {
        profile: () => "Perfil",
        editProfile: () => "Perfil",
        editAvatar: () => "Cambiar foto de perfil",
        deleteProfile: () => "Borrar perfil",
        logOut: () => "Cerrar sesión",
        settings: () => "Configuración",
        memberSince: () => "Miembro desde",
        displayLang: () => "Idioma de la interfaz",
        done: () => "Listo",
        save: () => "Guardar",
        notif: () => "Notificaciones",
      },
      youtube: {
        // TODO: () =>add link here
        kindlyNote: () =>
          'Por favor, tenga en cuenta que LangFire se encuentra actualmente en su fase alfa y su funcionalidad puede no cumplir con todas las expectativas. Además, tenga en cuenta que los transcripciones de YouTube pueden no estar siempre accesibles, precisas o completas. Si encuentra algún problema, le animamos a informarlo utilizando la función "Informar de un problema". Su retroalimentación es muy apreciada.',
        reportProb: () => "Informar de un problema",
        myVideos: () => "Mis videos",
        newVideo: () => "Nuevo video",
        beingProcessed: () => "En proceso",
        videosQueue: () => "Vídeos en cola",
      },
      wordCards: {
        flashCards: () => "Tarjetas de estudio",
        nextCard: () => "Próxima tarjeta lista",
        now: () => "Ahora",
        today: () => "Hoy",
        tomorrow: () => "Mañana",
        nextWeek: () => "La próxima semana",
        soon: () => "Pronto",
        total: () => "Total",
        level: () => "Nivel",
        add20: () => "Agregar 20 tarjetas",
        reveal: () => "Mostrar la respuesta",
        addNote: () => "Agregar una nota",
        seen: () => "Visto",
        familiar: () => "Familiar",
        good: () => "Bueno",
        known: () => "Conocido",
      },
      browseContent: {
        menu: () => "Menú",
        home: () => "Inicio",
        originalCont: () => "Contenido original",
        designOwn: () =>
          "Diseña tu propio viaje de aprendizaje. Crea lecciones personalizadas que se ajusten perfectamente a tus preferencias de aprendizaje únicas para máximos resultados.",
        langContent: () => "Contenido de LangFire",
        myContent: () => "Mi contenido",
        books: () => "Libros",
        kidsBooks: () => "Libros infantiles",
        audioBooks: () => "Audiolibros",
        wiki: () => "Wikipedia",
        discover: () => "Explorar",
        search: () => "Búsqueda",
        faves: () => "Favoritos",
        uploadContent: () => "Subir contenido de lectura",
        addText: () => "Agrega texto aquí...",
        upload: () => "Subir",
        chapter: () => "Capítulo",
        chapterTitle: () => "Título del capítulo",
        description: () => "Descripción",
      },
      contactFAQ: {
        rightsReserved: () => "©2024 Predicate Software Solutions Ltd. Todos los derechos reservados.",
        aboutUs: () => "Sobre nosotros",
        help: () => "Ayuda",
        giveFeedback: () => "Comentarios",
        submitFeedback: () => "Enviar comentarios",
        priccing: () => "Precios",
        contact: () => "Contacto",
      },
    },

    it: {
      startPage: {
        intro1: () =>
          "Registrati oggi e intraprendi il viaggio per imparare una nuova lingua! È completamente gratuito. Con la nostra app, puoi immergerti in un mondo di nuove parole e frasi, rendendo l'apprendimento facile e divertente.",
        intro2: () =>
          "Tieni traccia del tuo percorso con ogni parola e frase che acquisisci. Utilizza la nostra funzione per salvare i tuoi progressi, consentendoti di riflettere su quanto sei arrivato lontano. Scopri la soddisfazione di padroneggiare nuovo vocabolario e frasi mentre navighi attraverso la nostra piattaforma.",
        intro3: () =>
          "La nostra app è progettata per aiutarti a imparare una nuova lingua nel modo più efficiente possibile. Utilizziamo le ultime ricerche nell'apprendimento delle lingue per aiutarti a imparare una nuova lingua velocemente.",
        signUp: () => "Registrati",
        username: () => "Nome utente",
        email: () => "E-mail",
        password: () => "Password",
        confirmPw: () => "Conferma password",
        logIn: () => "Accedi",
        welcomeBack: () => "Bentornato!",
        registerFree: () => "Iscriviti gratis",
      },
      profileSettings: {
        profile: () => "Profilo",
        editProfile: () => "Modifica profilo",
        editAvatar: () => "Cambia immagine del profilo",
        deleteProfile: () => "Elimina profilo",
        logOut: () => "Esci",
        settings: () => "Impostazioni",
        memberSince: () => "Membro da",
        displayLang: () => "Lingua dell'interfaccia",
        done: () => "Fatto",
        save: () => "Salva",
        notif: () => "Notifiche",
      },
      youtube: {
        // TODO: () =>add link here
        kindlyNote: () =>
          'Si prega di notare che LangFire è attualmente nella sua fase alpha e la sua funzionalità potrebbe non soddisfare tutte le aspettative. Inoltre, si tenga presente che le trascrizioni da YouTube potrebbero non essere sempre accessibili, accurate o complete. Se riscontri qualche problema, ti incoraggiamo a segnalarlo utilizzando la funzione "Segnala un problema". Il tuo feedback è molto apprezzato.',
        reportProb: () => "Segnala un problema",
        myVideos: () => "I miei video",
        newVideo: () => "Nuovo video",
        beingProcessed: () => "Elaborazione in corso",
        videosQueue: () => "Video in coda",
      },
      wordCards: {
        flashCards: () => "Flashcard",
        nextCard: () => "Prossima carta pronta",
        now: () => "Ora",
        today: () => "Oggi",
        tomorrow: () => "Domani",
        nextWeek: () => "La prossima settimana",
        soon: () => "Presto",
        total: () => "Totale",
        level: () => "Livello",
        add20: () => "Aggiungi 20 carte",
        reveal: () => "Mostra la risposta",
        addNote: () => "Aggiungi una nota",
        seen: () => "Visto",
        familiar: () => "Familiare",
        good: () => "Buono",
        known: () => "Conosciuto",
      },
      browseContent: {
        menu: () => "Menu",
        home: () => "Home",
        originalCont: () => "Contenuti originali",
        designOwn: () =>
          "Progetta il tuo percorso di apprendimento. Crea lezioni personalizzate che si adattino perfettamente alle tue preferenze di apprendimento uniche per massimizzare l'efficacia.",
        langContent: () => "Contenuti LangFire",
        myContent: () => "I miei contenuti",
        books: () => "Libri",
        kidsBooks: () => "Libri per bambini",
        audioBooks: () => "Audiolibri",
        wiki: () => "Wikipedia",
        discover: () => "Esplora",
        search: () => "Cerca",
        faves: () => "Preferiti",
        uploadContent: () => "Carica contenuti di lettura",
        addText: () => "Aggiungi il testo qui...",
        upload: () => "Carica",
        chapter: () => "Capitolo",
        chapterTitle: () => "Titolo del capitolo",
        description: () => "Descrizione",
      },
      contactFAQ: {
        rightsReserved: () => "©2024 Predicate Software Solutions Ltd. Tutti i diritti riservati.",
        aboutUs: () => "Chi siamo",
        help: () => "Assistenza",
        giveFeedback: () => "Lascia un feedback",
        submitFeedback: () => "Invia feedback",
        priccing: () => "Prezzi",
        contact: () => "Contatti",
      },
    },

    fr: {
      startPage: {
        intro1: () =>
          "Inscrivez-vous aujourd'hui et lancez-vous dans l'apprentissage d'une nouvelle langue ! C'est entièrement gratuit. Avec notre application, plongez dans un monde de nouveaux mots et expressions, rendant l'apprentissage facile et plaisant.",
        intro2: () =>
          "Suivez votre progression avec chaque mot et phrase que vous apprenez. Utilisez notre fonctionnalité pour sauvegarder vos progrès, vous permettant de réfléchir à la distance que vous avez parcourue. Découvrez la satisfaction de maîtriser de nouveaux vocabulaires et expressions alors que vous naviguez sur notre plateforme.",
        intro3: () =>
          "Notre application est conçue pour vous aider à apprendre une nouvelle langue de la manière la plus efficace possible. Nous utilisons les dernières recherches en matière d'apprentissage des langues pour vous aider à apprendre une nouvelle langue rapidement.",
        signUp: () => "S'inscrire",
        username: () => "Nom d'utilisateur",
        email: () => "Courriel",
        password: () => "Mot de passe",
        confirmPw: () => "Confirmer le mot de passe",
        logIn: () => "Se connecter",
        welcomeBack: () => "Bienvenue de retour !",
        registerFree: () => "Inscris-toi gratuitement",
      },
      profileSettings: {
        profile: () => "Profil",
        editProfile: () => "Modifiez le profil",
        editAvatar: () => "Modifier la photo de profil",
        deleteProfile: () => "Supprimer le profil",
        logOut: () => "Se déconnecter",
        settings: () => "Paramètres",
        memberSince: () => "Membre depuis",
        displayLang: () => "Langue de l'interface",
        done: () => "Terminé",
        save: () => "Enregistrer",
        notif: () => "Notifications",
      },
      youtube: {
        // TODO: () =>add link here
        kindlyNote: () =>
          "Veuillez noter que LangFire est actuellement en phase alpha et que sa fonctionnalité peut ne pas répondre à toutes les attentes. De plus, veuillez noter que les transcriptions de YouTube peuvent ne pas toujours être accessibles, précises ou complètes. Si vous rencontrez des problèmes, nous vous encourageons à les signaler en utilisant la fonction 'Signaler un problème'. Vos commentaires sont très appréciés.",
        reportProb: () => "Signaler un problème",
        myVideos: () => "Mes vidéos",
        newVideo: () => "Nouvelle vidéo",
        beingProcessed: () => "En cours de traitement",
        videosQueue: () => "Vidéos en file d'attente",
      },
      wordCards: {
        flashCards: () => "Cartes mémoire",
        nextCard: () => "Prochaine carte prête",
        now: () => "Maintenant",
        today: () => "Aujourd'hui",
        tomorrow: () => "Demain",
        nextWeek: () => "La semaine prochaine",
        soon: () => "Bientôt",
        total: () => "Total",
        level: () => "Niveau",
        add20: () => "Ajouter 20 cartes",
        reveal: () => "Afficher la réponse",
        addNote: () => "Ajouter une note",
        seen: () => "Vu",
        familiar: () => "Familier",
        good: () => "Bon",
        known: () => "Connu",
      },
      browseContent: {
        menu: () => "Menu",
        home: () => "Accueil",
        originalCont: () => "Contenu original",
        designOwn: () =>
          "Concevez votre propre parcours d'apprentissage. Créez des leçons personnalisées qui correspondent parfaitement à vos préférences d'apprentissage uniques pour une efficacité maximale.",
        langContent: () => "Contenu LangFire",
        myContent: () => "Mon contenu",
        books: () => "Livres",
        kidsBooks: () => "Livres pour enfants",
        audioBooks: () => "Livres audio",
        wiki: () => "Wikipédia",
        discover: () => "Parcourir",
        search: () => "Recherche",
        faves: () => "Favoris",
        uploadContent: () => "Ajouter du contenu de lecture",
        addText: () => "Ajoutez le texte ici...",
        upload: () => "Uploader",
        chapter: () => "Chapitre",
        chapterTitle: () => "Titre du chapitre",
        description: () => "Description",
      },
      contactFAQ: {
        rightsReserved: () => "©2024 Predicate Software Solutions Ltd. tous droits réservés.",
        aboutUs: () => "Qui sommes-nous",
        help: () => "Aide",
        giveFeedback: () => "Commentaires",
        submitFeedback: () => "Partager un avis",
        priccing: () => "Prix",
        contact: () => "Contact",
      },
    },

    du: {
      startPage: {
        intro1: () =>
          "Meld je vandaag aan en begin aan de reis om een nieuwe taal te leren! Het is volledig gratis. Met onze app kun je je onderdompelen in een wereld van nieuwe woorden en zinnen, waardoor leren gemakkelijk en plezierig wordt.",
        intro2: () =>
          "Houd je voortgang bij met elk woord en elke zin die je verwerft. Maak gebruik van onze functie om je voortgang op te slaan, waardoor je kunt terugkijken op hoever je al gekomen bent. Ontdek de voldoening van het beheersen van nieuwe woordenschat en zinnen terwijl je door ons platform navigeert.",
        intro3: () =>
          "Onze app is ontworpen om u te helpen een nieuwe taal op de meest efficiënte manier mogelijk te leren. We maken gebruik van het nieuwste onderzoek op het gebied van taalleren om u te helpen snel een nieuwe taal te leren.",
        signUp: () => "Aanmelden",
        username: () => "Gebruikersnaam",
        email: () => "E-mail",
        password: () => "Wachtwoord",
        confirmPw: () => "Wachtwoord bevestigen",
        logIn: () => "Inloggen",
        welcomeBack: () => "Welkom terug!",
        registerFree: () => "Gratis registreren",
      },
      profileSettings: {
        profile: () => "Profiel",
        editProfile: () => "Profiel bewerken",
        editAvatar: () => "Profielfoto wijzigen",
        deleteProfile: () => "Profiel verwijderen",
        logOut: () => "Uitloggen",
        settings: () => "Instellingen",
        memberSince: () => "Lid sinds",
        displayLang: () => "App-taal",
        done: () => "Klaar",
        save: () => "Opslaan",
        notif: () => "Meldingen",
      },
      youtube: {
        // TODO: () =>add link here
        kindlyNote: () =>
          'LangFire bevindt zich momenteel in de alfaversie en de functionaliteit voldoet mogelijk niet aan alle verwachtingen. Houd er ook rekening mee dat transcripties van YouTube niet altijd toegankelijk, nauwkeurig of volledig kunnen zijn. Als u problemen ondervindt, raden we u aan om ze te melden via de functie "Probleem melden". Uw feedback wordt zeer op prijs gesteld.',
        reportProb: () => "Probleem melden",
        myVideos: () => "Mijn video's",
        newVideo: () => "Nieuwe video",
        beingProcessed: () => "Wordt verwerkt",
        videosQueue: () => "Video's in de wachtrij",
      },
      wordCards: {
        flashCards: () => "Flashkaarten",
        nextCard: () => "Volgende kaart gereed",
        now: () => "Nu",
        today: () => "Vandaag",
        tomorrow: () => "Morgen",
        nextWeek: () => "Volgende week",
        soon: () => "Binnenkort",
        total: () => "Totaal",
        level: () => "Niveau",
        add20: () => "Voeg 20 kaartjes toe",
        reveal: () => "Antwoord weergeven",
        addNote: () => "Voeg een opmerking toe",
        seen: () => "Gezien",
        familiar: () => "Herkenbaar",
        good: () => "Goed",
        known: () => "Bekend",
      },
      browseContent: {
        menu: () => "Menu",
        home: () => "Home",
        originalCont: () => "Originele inhoud",
        designOwn: () =>
          "Ontwerp je eigen leertraject. Maak op maat gemaakte lessen die perfect aansluiten bij je voorkeuren.",
        langContent: () => "LangFire inhoud",
        myContent: () => "Mijn inhoud",
        books: () => "Boeken",
        kidsBooks: () => "Kinderboeken",
        audioBooks: () => "Luisterboeken",
        wiki: () => "Wikipedia",
        discover: () => "Ontdekken",
        search: () => "Zoeken",
        faves: () => "Favorieten",
        uploadContent: () => "Upload leesmateriaal",
        addText: () => "Voeg hier tekst toe...",
        upload: () => "Uploaden",
        chapter: () => "Hoofdstuk",
        chapterTitle: () => "Hoofdstuktitel",
        description: () => "Beschrijving",
      },
      contactFAQ: {
        rightsReserved: () => "©2024 Predicate Software Solutions Ltd. Alle rechten voorbehouden.",
        aboutUs: () => "Info",
        help: () => "Help",
        giveFeedback: () => "Feedback geven",
        submitFeedback: () => "Fedback gsturen",
        priccing: () => "Prijzen",
        contact: () => "Contact",
      },
    },

    hu: {
      startPage: {
        intro1: () =>
          "Regisztrálj ma és kezd el egy új nyelv tanulásának útjára! Teljesen ingyenes. Az alkalmazásunkkal belemerülhetsz az új szavak és kifejezések világába, ami könnyűvé és élvezetessé teszi a tanulást.",
        intro2: () =>
          "Kövesd nyomon az előrehaladásodat minden egyes megtanult szóval és kifejezéssel. Használd ki funkcióinkat az előrehaladásod mentésére, így visszatekintve láthatod, milyen messzire jutottál. Fedezd fel az új szókincs és kifejezések mesterségének örömét, ahogy átböngészed a platformunkat.",
        intro3: () =>
          "Az alkalmazásunk úgy van tervezve, hogy segítsen neked a legkézenfekvőbb módon megtanulni egy új nyelvet. A legfrissebb nyelvtanulási kutatásokat használjuk fel, hogy segítsünk neked gyorsan megtanulni egy új nyelvet.",
        signUp: () => "Regisztráció",
        username: () => "Felhasználónév",
        email: () => "E-mail",
        password: () => "Jelszó",
        confirmPw: () => "Jelszó megerősítése",
        logIn: () => "Bejelentkezés",
        welcomeBack: () => "Örülünk, hogy újra látjuk!",
        registerFree: () => "Ingyenesen regisztrálj",
      },
      profileSettings: {
        profile: () => "Profil",
        editProfile: () => "Profil szerkesztése",
        editAvatar: () => "Profilkép módosítása",
        deleteProfile: () => "Profil törlése",
        logOut: () => "Kijelentkezés",
        settings: () => "Alkalmazásbeállítások",
        memberSince: () => "Tagként",
        displayLang: () => "Alkalmazás nyelv",
        done: () => "Kész",
        save: () => "Mentés",
        notif: () => "Értesítések",
      },
      youtube: {
        // TODO: () =>add link here
        kindlyNote: () =>
          'Kérjük, vegye figyelembe, hogy a LangFire jelenleg az alfa fázisban van, és funkcionalitása nem feltétlenül felel meg minden elvárásnak. Emellett vegye figyelembe, hogy a YouTube tranzkripciós szövegei nem mindig elérhetőek, pontosak vagy teljesek. Ha bármilyen problémába ütközik, arra ösztönözzük, hogy jelezze azokat a "Probléma jelentése" funkció használatával. Nagyon értékeljük visszajelzését.',
        reportProb: () => "Probléma jelentése",
        myVideos: () => "Saját videóim",
        newVideo: () => "Új videó",
        beingProcessed: () => "Folyamatban van",
        videosQueue: () => "Videók a várólistán",
      },
      wordCards: {
        flashCards: () => "Tanulókártyák",
        nextCard: () => "Következő kártya kész",
        now: () => "Most",
        today: () => "Ma",
        tomorrow: () => "Holnap",
        nextWeek: () => "Jövő hét",
        soon: () => "Hamarosan",
        total: () => "Összesen",
        level: () => "Szint",
        add20: () => "Adjon hozzá 20 kártyát",
        reveal: () => "Mutasd a választ",
        addNote: () => "Adjon hozzá jegyzetet",
        seen: () => "Látott",
        familiar: () => "Ismerős",
        good: () => "Jó",
        known: () => "Ismert",
      },
      browseContent: {
        menu: () => "Menü",
        home: () => "Főoldal",
        originalCont: () => "Eredeti tartalom",
        designOwn: () =>
          "Tervezd meg saját tanulási utadat. Hozz létre testreszabott leckéket, amelyek tökéletesen illeszkednek egyedi tanulási preferenciáidhoz a maximális hatás érdekében.",
        langContent: () => "LangFire tartalom",
        myContent: () => "Tartalmaim",
        books: () => "Könyvek",
        kidsBooks: () => "Gyerekkönyvek",
        audioBooks: () => "Hangoskönyvek",
        wiki: () => "Wikipédia",
        discover: () => "Felfedezés",
        search: () => "Keresés",
        faves: () => "Kedvencek",
        uploadContent: () => "Olvasási tartalom feltöltése",
        addText: () => "Írd ide a szöveget...",
        upload: () => "Feltöltés",
        chapter: () => "Fejezet",
        chapterTitle: () => "Fejezetcím",
        description: () => "Leírás",
      },
      contactFAQ: {
        rightsReserved: () => "©2024 Predicate Software Solutions Ltd. Minden jog fenntartva.",
        aboutUs: () => "Rólunk",
        help: () => "Súgó",
        giveFeedback: () => "Visszajelzés küldése",
        submitFeedback: () => "Visszajelzés beküldése",
        priccing: () => "Árak",
        contact: () => "Kapcsolat",
      },
    },

    ko: {
      startPage: {
        intro1: () =>
          "오늘 가입하여 새로운 언어 학습 여정을 떠나보세요! 완전 무료입니다. 당사 앱을 통해 새로운 단어와 구문의 세계로 빠져들어 학습을 쉽고 즐겁게 만들 수 있습니다.",
        intro2: () =>
          "당신이 습득한 각 단어와 구문으로 여정을 추적하세요. 진도를 저장하여 얼마나 멀리 왔는지 되돌아보세요. 당사 플랫폼을 통해 새로운 어휘와 구문을 마스터하는 만족감을 느껴보세요.",
        intro3: () =>
          "저희 앱은 새로운 언어를 가장 효율적인 방법으로 배울 수 있도록 설계되었습니다. 최신 언어 학습 연구를 활용하여 새로운 언어를 빠르게 배울 수 있도록 도와드립니다.",
        signUp: () => "가입하기",
        username: () => "사용자 이름",
        email: () => "이메일",
        password: () => "비밀번호",
        confirmPw: () => "비밀번호 확인",
        logIn: () => "로그인",
        welcomeBack: () => "다시 오신 것을 환영합니다!",
        registerFree: () => "무료로 가입하세요",
      },
      profileSettings: {
        profile: () => "프로필",
        editProfile: () => "프로필 수정",
        editAvatar: () => "프로필 사진 변경",
        deleteProfile: () => "프로필 삭제",
        logOut: () => "로그아웃",
        settings: () => "설정",
        memberSince: () => "회원 이후",
        displayLang: () => "표시 언어",
        done: () => "완료",
        save: () => "저장",
        notif: () => "알림",
      },
      youtube: {
        // TODO: () =>add link here
        kindlyNote: () =>
          'LangFire가 현재 알파 단계에 있으며 기능이 모든 기대에 부응하지 않을 수 있음을 참고해 주세요. 또한 YouTube에서의 트랜스크립트가 항상 접근 가능하거나 정확하거나 완전하지 않을 수 있습니다. 문제가 발생하면 "문제 신고" 기능을 사용하여 문제를 보고해 주시기 바랍니다. 귀하의 의견은 매우 중요합니다.',
        reportProb: () => "문제 신고",
        myVideos: () => "내 동영상",
        newVideo: () => "새로운 비디오",
        beingProcessed: () => "처리 중입니다",
        videosQueue: () => "대기열 비디오",
      },
      wordCards: {
        flashCards: () => "플래시 카드",
        nextCard: () => "다음 카드 준비 완료",
        now: () => "지금",
        today: () => "오늘",
        tomorrow: () => "내일",
        nextWeek: () => "다음 주",
        soon: () => "곧",
        total: () => "총수",
        level: () => "레벨",
        add20: () => "20 장의 카드 추가",
        reveal: () => "답 보기",
        addNote: () => "노트 추가",
        seen: () => "본",
        familiar: () => "친숙한",
        good: () => "좋은",
        known: () => "알려진",
      },
      browseContent: {
        menu: () => "메뉴",
        home: () => "홈",
        originalCont: () => "오리지널 콘텐츠",
        designOwn: () =>
          "당신만의 학습 여정을 디자인하세요. 독특한 학습 선호도에 완벽하게 맞는 맞춤형 수업을 만들어 최대 효과를 누리세요.",
        langContent: () => "콘텐츠",
        myContent: () => "내 콘텐츠",
        books: () => "책",
        kidsBooks: () => "어린이 책",
        audioBooks: () => "오디오북",
        wiki: () => "위키백과",
        discover: () => "컬렉션 둘러보기",
        search: () => "검색",
        faves: () => "즐겨찾기",
        uploadContent: () => "독서 콘텐츠 업로드",
        addText: () => "여기에 텍스트를 입력하세요...",
        upload: () => "업로드",
        chapter: () => "장",
        chapterTitle: () => "장 제목",
        description: () => "설명",
      },
      contactFAQ: {
        rightsReserved: () => "©2024 Predicate Software Solutions Ltd. 모든 권리 보유.",
        aboutUs: () => "회사 소개",
        help: () => "도움말",
        giveFeedback: () => "피드백 공유",
        submitFeedback: () => "피드백 제출하기",
        priccing: () => "가격",
        contact: () => "연락처",
      },
    },

    pt: {
      startPage: {
        intro1: () =>
          "Inscreva-se hoje mesmo e embarque na jornada para aprender um novo idioma! É completamente gratuito. Com o nosso aplicativo, você pode mergulhar em um mundo de novas palavras e frases, tornando o aprendizado fácil e divertido.",
        intro2: () =>
          "Acompanhe a sua jornada com cada palavra e frase que adquire. Utilize a nossa funcionalidade para guardar o seu progresso, permitindo-lhe refletir sobre o quão longe chegou. Descubra a satisfação de dominar novo vocabulário e frases enquanto navega pela nossa plataforma.",
        intro3: () =>
          "O nosso aplicativo foi projetado para ajudá-lo a aprender um novo idioma da forma mais eficiente possível. Utilizamos as pesquisas mais recentes em aprendizado de idiomas para ajudá-lo a aprender um novo idioma rapidamente.",
        signUp: () => "Registar-se",
        username: () => "Nome de utilizador",
        email: () => "Correio eletrónico",
        password: () => "Palavra-passe",
        confirmPw: () => "Confirmar Palavra-passe",
        logIn: () => "Entrar",
        welcomeBack: () => "Bem-vindo de volta!",
        registerFree: () => "Inscreva-se gratuitamente ",
      },
      profileSettings: {
        profile: () => "Perfil",
        editProfile: () => "Editar perfil",
        editAvatar: () => "Alterar foto do perfil",
        deleteProfile: () => "Eliminar perfil",
        logOut: () => "Sair",
        settings: () => "Definições",
        memberSince: () => "Membro desde",
        displayLang: () => "Idioma da aplicação",
        done: () => "Concluído",
        save: () => "Guardar",
        notif: () => "Notificações",
      },
      youtube: {
        // TODO: () =>add link here
        kindlyNote: () =>
          'Por favor, tenha em conta que o LangFire está atualmente na sua fase alfa e a sua funcionalidade pode não corresponder a todas as expectativas. Além disso, esteja ciente de que as transcrições do YouTube podem não estar sempre acessíveis, precisas ou completas. Se encontrar algum problema, encorajamos a que o reporte utilizando a funcionalidade "Reportar problema". O seu feedback é muito apreciado.',
        reportProb: () => "Reportar problema",
        myVideos: () => "Os meus vídeos",
        newVideo: () => "Novo vídeo",
        beingProcessed: () => "Em processamento",
        videosQueue: () => "Vídeos na fila",
      },
      wordCards: {
        flashCards: () => "Cartões de memória",
        nextCard: () => "Próximo cartão pronto",
        now: () => "Agora",
        today: () => "Hoje",
        tomorrow: () => "Amanhã",
        nextWeek: () => "Na próxima semana",
        soon: () => "Em breve",
        total: () => "Total",
        level: () => "Nível",
        add20: () => "Adicionar 20 cartas",
        reveal: () => "Mostrar a resposta",
        addNote: () => "Adicionar uma nota",
        seen: () => "Visto",
        familiar: () => "Familiar",
        good: () => "Bom",
        known: () => "Conhecido",
      },
      browseContent: {
        menu: () => "Menu",
        home: () => "Início",
        originalCont: () => "Conteúdo original",
        designOwn: () =>
          "Desenha a tua própria jornada de aprendizagem. Cria lições personalizadas que se adaptem perfeitamente às tuas preferências de aprendizagem únicas para uma eficácia máxima.",
        langContent: () => "Conteúdo da LangFire",
        myContent: () => "Meu conteúdo",
        books: () => "Livros",
        kidsBooks: () => "Livros infantis",
        audioBooks: () => "Audiolivros",
        wiki: () => "Wikipédia",
        discover: () => "Explorar",
        search: () => "Pesquisa",
        faves: () => "Favoritos",
        uploadContent: () => "Enviar conteúdo de leitura",
        addText: () => "Adicione texto aqui...",
        upload: () => "Carregar",
        chapter: () => "Capítulo",
        chapterTitle: () => "Título do capítulo",
        description: () => "Descrição",
      },
      contactFAQ: {
        rightsReserved: () => "©2024 Predicate Software Solutions Ltd. Todos os direitos reservados.",
        aboutUs: () => "Sobre nós",
        help: () => "Ajuda",
        giveFeedback: () => "Dar feedback",
        submitFeedback: () => "Enviar feedback",
        priccing: () => "Preços",
        contact: () => "Contactos",
      },
    },

    br: {
      startPage: {
        intro1: () =>
          "Crie uma conta hoje mesmo e comece a aprender um novo idioma! É totalmente gratuito. Com nosso aplicativo, você pode explorar um mundo de novas palavras e frases, tornando o aprendizado fácil e divertido.",
        intro2: () =>
          "Acompanhe sua jornada com cada palavra e frase que você adquire. Utilize nossa função para salvar seu progresso, permitindo que você reflita sobre o quanto avançou. Descubra a satisfação de dominar novo vocabulário e frases enquanto navega em nossa plataforma.",
        intro3: () =>
          "Nosso aplicativo foi projetado para ajudá-lo a aprender um novo idioma da maneira mais eficiente possível. Utilizamos as pesquisas mais recentes em aprendizado de idiomas para ajudá-lo a aprender um novo idioma rapidamente.",
        signUp: () => "Registrar-se",
        username: () => "Nome de usuário",
        email: () => "Email",
        password: () => "Senha",
        confirmPw: () => "Confirmar senha",
        logIn: () => "Iniciar sessão",
        welcomeBack: () => "Bem-vindo de volta!",
        registerFree: () => "Idioma do aplicativo",
      },
      profileSettings: {
        profile: () => "Perfil",
        editProfile: () => "Editar perfil",
        editAvatar: () => "Alterar foto do perfil",
        deleteProfile: () => "Excluir perfil",
        logOut: () => "Sair",
        settings: () => "Configurações",
        memberSince: () => "Membro desde",
        displayLang: () => "Idioma do aplicativo",
        done: () => "Pronto",
        save: () => "Salvar",
        notif: () => "Notificações",
      },
      youtube: {
        // TODO: () =>add link here
        kindlyNote: () =>
          'Por favor, note que o LangFire está atualmente em sua fase alfa e sua funcionalidade pode não atender a todas as expectativas. Além disso, esteja ciente de que as transcrições do YouTube podem não estar sempre acessíveis, precisas ou completas. Se encontrar algum problema, incentivamos você a relatar usando o recurso "Reportar problema". Seu feedback é muito apreciado.',
        reportProb: () => "Reportar problema",
        myVideos: () => "Meus vídeos",
        newVideo: () => "Novo vídeo",
        beingProcessed: () => "Em processamento",
        videosQueue: () => "Vídeos na fila",
      },
      wordCards: {
        flashCards: () => "Cartões de memória",
        nextCard: () => "Próximo cartão pronto",
        now: () => "Agora",
        today: () => "Hoje",
        tomorrow: () => "Amanhã",
        nextWeek: () => "Na próxima semana",
        soon: () => "Em breve",
        total: () => "Total",
        level: () => "Nível",
        add20: () => "Adicionar 20 cartas",
        reveal: () => "Mostrar a resposta",
        addNote: () => "Adicionar uma nota",
        seen: () => "Visto",
        familiar: () => "Familiar",
        good: () => "Bom",
        known: () => "Conhecido",
      },
      browseContent: {
        menu: () => "Menu",
        home: () => "Início",
        originalCont: () => "Conteúdo original",
        designOwn: () =>
          "Designe sua própria jornada de aprendizado. Crie lições personalizadas que se ajustem perfeitamente às suas preferências de aprendizagem únicas para uma eficácia máxima.",
        langContent: () => "Conteúdo LangFire",
        myContent: () => "Meus conteúdos",
        books: () => "Livros",
        kidsBooks: () => "Livros infantis",
        audioBooks: () => "Audiolivros",
        wiki: () => "Wikipédia",
        discover: () => "Explorar",
        search: () => "Pesquisa",
        faves: () => "Favoritos",
        uploadContent: () => "Envie conteúdo de leitura",
        addText: () => "Adicione texto aqui...",
        upload: () => "Carregar",
        chapter: () => "Capítulo",
        chapterTitle: () => "Título do capítulo",
        description: () => "Descrição",
      },
      contactFAQ: {
        rightsReserved: () => "©2024 Predicate Software Solutions Ltd. Todos os direitos reservados.",
        aboutUs: () => "Sobre nós",
        help: () => "Ajuda",
        giveFeedback: () => "Comentários",
        submitFeedback: () => "Enviar",
        priccing: () => "Preços",
        contact: () => "Contatos",
      },
    },

    cz: {
      startPage: {
        intro1: () =>
          "Zaregistrujte se dnes a vydejte se na cestu k učení nového jazyka! Je to zcela zdarma. S naší aplikací se můžete ponořit do světa nových slov a frází, což činí učení snadným a zábavným.",
        intro2: () =>
          "Sledujte svou cestu s každým slovem a frází, které získáte. Využijte naši funkci k ukládání vašeho pokroku, což vám umožní ohlédnout se za tím, jak daleko jste již dospěli. Objevte uspokojení z ovládání nové slovní zásoby a frází při navigaci naší platformou.",
        intro3: () =>
          "Naše aplikace je navržena tak, aby vám pomohla naučit se nový jazyk co nejefektivnějším způsobem. Používáme nejnovější výzkumy v oblasti jazykového učení, abychom vám pomohli rychleji se naučit nový jazyk.",
        signUp: () => "Registrovat se",
        username: () => "Uživatelské jméno",
        email: () => "E-mail",
        password: () => "Heslo",
        confirmPw: () => "Potvrďte heslo",
        logIn: () => "Přihlásit se",
        welcomeBack: () => "Vítejte zpět!",
        registerFree: () => "Registruj se zdarma",
      },
      profileSettings: {
        profile: () => "Profil",
        editProfile: () => "Úprava profilu",
        editAvatar: () => "Změnit profilový obrázek",
        deleteProfile: () => "Smazat profil",
        logOut: () => "Odhlásit se",
        settings: () => "Nastavení",
        memberSince: () => "Člen od",
        displayLang: () => "Jazyk aplikace",
        done: () => "Hotovo",
        save: () => "Uložit",
        notif: () => "Oznámení",
      },
      youtube: {
        // TODO: () =>add link here
        kindlyNote: () =>
          'Upozorňujeme, že LangFire se momentálně nachází ve fázi alfa a jeho funkčnost nemusí odpovídat všem očekáváním. Kromě toho mějte na paměti, že transkripce z YouTube nemusí být vždy dostupné, přesné nebo úplné. Pokud narazíte na nějaké problémy, doporučujeme je hlásit pomocí funkce "Nahlásit problém". Vaše zpětná vazba je velmi ceněna',
        reportProb: () => "Nahlásit problém",
        myVideos: () => "Moje videa",
        newVideo: () => "Nové video",
        beingProcessed: () => "Je zpracováváno",
        videosQueue: () => "Videa ve frontě",
      },
      wordCards: {
        flashCards: () => "Flashcards",
        nextCard: () => "Připravena další karta",
        now: () => "Nyní",
        today: () => "Dnes",
        tomorrow: () => "Zítra",
        nextWeek: () => "Příští týden",
        soon: () => "Brzy",
        total: () => "Celkem",
        level: () => "Úroveň",
        add20: () => "Přidat 20 karet",
        reveal: () => "Zobrazit odpověď",
        addNote: () => "Přidat poznámku",
        seen: () => "Viděno",
        familiar: () => "Známé",
        good: () => "Dobré",
        known: () => "Znalý",
      },
      browseContent: {
        menu: () => "Menu",
        home: () => "Domů",
        originalCont: () => "Původní obsah",
        designOwn: () =>
          "Navrhni si vlastní učební cestu. Vytvoř si vlastní lekce, které perfektně odpovídají tvým jedinečným preferencím pro maximální efektivitu.",
        langContent: () => "Obsah LangFire",
        myContent: () => "Můj obsah",
        books: () => "Knihy",
        kidsBooks: () => "Dětské knihy",
        audioBooks: () => "Audioknihy",
        wiki: () => "Wikipedie",
        discover: () => "Objevujte",
        search: () => "Hledat",
        faves: () => "Oblíbené",
        uploadContent: () => "Nahrajte obsah ke čtení",
        addText: () => "Přidejte text zde...",
        upload: () => "Nahrát",
        chapter: () => "Kapitola",
        chapterTitle: () => "Název kapitoly",
        description: () => "Popis",
      },
      contactFAQ: {
        rightsReserved: () => "©2024 Predicate Software Solutions Ltd. Všechna práva vyhrazena.",
        aboutUs: () => "O nás",
        help: () => "Nápověda",
        giveFeedback: () => "Odeslat zpětnou vazbu",
        submitFeedback: () => "Poslat zpětnou vazbu",
        priccing: () => "Ceny",
        contact: () => "Kontakty",
      },
    },

    po: {
      startPage: {
        intro1: () =>
          "Zarejestruj się już dziś i rozpocznij naukę nowego języka! To całkowicie za darmo. Dzięki naszej aplikacji możesz zagłębić się w świat nowych słów i zwrotów, ucząc się w prosty i przyjemny sposób.",
        intro2: () =>
          "Śledź swój postęp z każdym słowem i frazą, które przyswoisz. Skorzystaj z naszej funkcji, aby zapisać swój postęp, co pozwoli ci zastanowić się, jak daleko już doszedłeś. Odkryj satysfakcję z opanowania nowego słownictwa i zwrotów podczas przeglądania naszej platformy.",
        intro3: () =>
          "Nasza aplikacja została zaprojektowana, aby pomóc Ci nauczyć się nowego języka w najbardziej efektywny sposób. Wykorzystujemy najnowsze badania w dziedzinie nauki języków, aby pomóc Ci szybko nauczyć się nowego języka.",
        signUp: () => "Zarejestruj się",
        username: () => "Nazwa użytkownika",
        email: () => "E-mail",
        password: () => "Hasło",
        confirmPw: () => "Potwierdź hasło",
        logIn: () => "Zaloguj się",
        welcomeBack: () => "Witamy z powrotem!",
        registerFree: () => "Zarejestruj się za darmo",
      },
      profileSettings: {
        profile: () => "Profil",
        editProfile: () => "Edytuj profil",
        editAvatar: () => "Zmień zdjęcie profilowe",
        deleteProfile: () => "Usuń profil",
        logOut: () => "Wyloguj się",
        settings: () => "Ustawienia",
        memberSince: () => "Członek od",
        displayLang: () => "Język aplikacji",
        done: () => "Gotowe",
        save: () => "Zapisz",
        notif: () => "Powiadomienia",
      },
      youtube: {
        // TODO: () =>add link here
        kindlyNote: () =>
          'Prosimy pamiętać, że LangFire jest obecnie w fazie alfa, a jego funkcjonalność może nie spełniać wszystkich oczekiwań. Ponadto, należy pamiętać, że transkrypcje z YouTube mogą nie zawsze być dostępne, dokładne lub pełne. Jeśli napotkasz jakiekolwiek problemy, zachęcamy do ich zgłaszania za pomocą funkcji "Zgłoś problem". Twoja opinia jest bardzo ceniona.',
        reportProb: () => "Zgłoś problem",
        myVideos: () => "Moje filmy",
        newVideo: () => "Nowy film",
        beingProcessed: () => "Jest przetwarzane",
        videosQueue: () => "Filmy w kolejce",
      },
      wordCards: {
        flashCards: () => "Fiszki",
        nextCard: () => "Następna fiszka gotowa",
        now: () => "Teraz",
        today: () => "Dzisiaj",
        tomorrow: () => "Jutro",
        nextWeek: () => "Przyszły tydzień",
        soon: () => "Wkrótce",
        total: () => "Razem",
        level: () => "Poziom",
        add20: () => "Dodaj 20 kart",
        reveal: () => "Pokazać odpowiedź",
        addNote: () => "Dodaj notatkę",
        seen: () => "Widziane",
        familiar: () => "Znajome",
        good: () => "Dobrze",
        known: () => "Znany",
      },
      browseContent: {
        menu: () => "Menu",
        home: () => "Strona główna",
        originalCont: () => "Oryginalna zawartość",
        designOwn: () =>
          "Zaprojektuj własną ścieżkę nauki. Stwórz niestandardowe lekcje, które idealnie dopasują się do twoich unikalnych preferencji nauki, dla maksymalnej skuteczności.",
        langContent: () => "Zawartość LangFire",
        myContent: () => "Moja zawartość",
        books: () => "Książki",
        kidsBooks: () => "Książki dla dzieci",
        audioBooks: () => "Audiobooki",
        wiki: () => "Wikipedia",
        discover: () => "Przeglądaj",
        search: () => "Wyszukaj",
        faves: () => "Ulubione",
        uploadContent: () => "Prześlij materiały do czytania",
        addText: () => "Wpisz tutaj tekst...",
        upload: () => "Prześlij",
        chapter: () => "Rozdział",
        chapterTitle: () => "Tytuł rozdziału",
        description: () => "Opis",
      },
      contactFAQ: {
        rightsReserved: () => "©2024 Predicate Software Solutions Ltd. Wszelkie prawa zastrzeżone.",
        aboutUs: () => "O nas",
        help: () => "Pomoc",
        giveFeedback: () => "Przekaż opinię",
        submitFeedback: () => "Prześlij opinię",
        priccing: () => "Cennik",
        contact: () => "Kontakt",
      },
    },

    da: {
      startPage: {
        intro1: () =>
          "Opret en konto i dag og begynd rejsen mod at lære et nyt sprog! Det er helt gratis. Med vores app kan du dykke ned i en verden af nye ord og sætninger, hvilket gør læring let og sjovt.",
        intro2: () =>
          "Hold styr på din rejse med hvert ord og hver sætning, du tilegner dig. Brug vores funktion til at gemme din fremskridt, så du kan reflektere over, hvor langt du er kommet. Opdag tilfredsstillelsen ved at mestre nyt ordforråd og sætninger, mens du navigerer gennem vores platform.",
        intro3: () =>
          "Vores app er designet til at hjælpe dig med at lære et nyt sprog på den mest effektive måde muligt. Vi bruger den seneste forskning inden for sprogindlæring til at hjælpe dig med at lære et nyt sprog hurtigt.",
        signUp: () => "Tilmeld dig",
        username: () => "Brugernavn",
        email: () => "E-mail",
        password: () => "Adgangskode",
        confirmPw: () => "Bekræft adgangskode",
        logIn: () => "Log ind",
        welcomeBack: () => "Velkommen tilbage!",
        registerFree: () => "Registrer dig gratis",
      },
      profileSettings: {
        profile: () => "Profil",
        editProfile: () => "Rediger profil",
        editAvatar: () => "Skift profilbillede",
        deleteProfile: () => "Slet profil",
        logOut: () => "Log ud",
        settings: () => "Indstillinger",
        memberSince: () => "Medlem siden",
        displayLang: () => "Appsprog",
        done: () => "Færdig",
        save: () => "Gem",
        notif: () => "Meddelelser",
      },
      youtube: {
        // TODO: () =>add link here
        kindlyNote: () =>
          'Vær opmærksom på, at LangFire i øjeblikket er i sin alfa-fase, og dens funktionalitet måske ikke lever op til alle forventninger. Derudover skal du være opmærksom på, at transskriptioner fra YouTube muligvis ikke altid er tilgængelige, nøjagtige eller komplette. Hvis du støder på problemer, opfordrer vi dig til at rapportere dem ved hjælp af "Rapporter problem"-funktionen. Din feedback er meget værdsat.',
        reportProb: () => "Rapporter problem",
        myVideos: () => "Mine videoer",
        newVideo: () => "Ny video",
        beingProcessed: () => "Bliver behandlet",
        videosQueue: () => "Videoer i køen",
      },
      wordCards: {
        flashCards: () => "Flash kort",
        nextCard: () => "Næste kort klar",
        now: () => "Nu",
        today: () => "I dag",
        tomorrow: () => "I morgen",
        nextWeek: () => "Næste uge",
        soon: () => "Snart",
        total: () => "Total",
        level: () => "Niveau",
        add20: () => "Tilføj 20 kort",
        reveal: () => "Vis svaret",
        addNote: () => "Tilføj en note",
        seen: () => "Set",
        familiar: () => "Kendt",
        good: () => "God",
        known: () => "Genkendt",
      },
      browseContent: {
        menu: () => "Menu",
        home: () => "Hjem",
        originalCont: () => "Originalt indhold",
        designOwn: () =>
          "Design din egen læringsrejse. Opret skræddersyede lektioner, der passer perfekt til dine unikke læringspræferencer for maksimal effektivitet.",
        langContent: () => "LangFire indhold",
        myContent: () => "Mit indhold",
        books: () => "Bøger",
        kidsBooks: () => "Børnebøger",
        audioBooks: () => "Lydbøger",
        wiki: () => "Wikipedia",
        discover: () => "Udforsk",
        search: () => "Søg",
        faves: () => "Favoritter",
        uploadContent: () => "Upload læseindhold",
        addText: () => "Tilføj tekst her...",
        upload: () => "Upload",
        chapter: () => "Kapitel",
        chapterTitle: () => "Kapiteloverskrift",
        description: () => "Beskrivelse",
      },
      contactFAQ: {
        rightsReserved: () => "©2024 Predicate Software Solutions Ltd. Alle rettigheder forbeholdes.",
        aboutUs: () => "Om os",
        help: () => "Hjælp",
        giveFeedback: () => "Giv feedback",
        submitFeedback: () => "Indsend feedback",
        priccing: () => "Priser",
        contact: () => "Kontakt",
      },
    },

    no: {
      startPage: {
        intro1: () =>
          "Registrer deg i dag og begynn reisen med å lære et nytt språk! Det er helt gratis. Med vår app kan du utforske en verden av nye ord og fraser, noe som gjør læring enkelt og morsomt.",
        intro2: () =>
          "Følg din læringsreise med hvert ord og hver setning du tilegner deg. Dra nytte av vår funksjon for å lagre din fremgang, slik at du kan reflektere over hvor langt du har kommet. Opplev gleden ved å mestre nytt vokabular og fraser mens du navigerer gjennom vår plattform.",
        intro3: () =>
          "Vår app er designet for å hjelpe deg med å lære et nytt språk på den mest effektive måten mulig. Vi bruker den nyeste forskningen innen språklæring for å hjelpe deg med å lære et nytt språk raskt.",
        signUp: () => "Registrer deg",
        username: () => "Brukernavn",
        email: () => "E-post",
        password: () => "Passord",
        confirmPw: () => "Bekreft passord",
        logIn: () => "Logg inn",
        welcomeBack: () => "Velkommen tilbake!",
        registerFree: () => "Registrer deg gratis",
      },
      profileSettings: {
        profile: () => "Profil",
        editProfile: () => "Rediger profil",
        editAvatar: () => "Bytt profilbilde",
        deleteProfile: () => "Slett profil",
        logOut: () => "Logg ut",
        settings: () => "Innstillinger",
        memberSince: () => "Medlem siden",
        displayLang: () => "Appspråk",
        done: () => "Ferdig",
        save: () => "Lagre",
        notif: () => "Varsler",
      },
      youtube: {
        // TODO: () =>add link here
        kindlyNote: () =>
          'Vær oppmerksom på at LangFire for øyeblikket er i alfa-fasen, og dens funksjonalitet kanskje ikke møter alle forventninger. I tillegg må du være klar over at transkripsjoner fra YouTube kanskje ikke alltid er tilgjengelige, nøyaktige eller komplette. Hvis du opplever noen problemer, oppfordrer vi deg til å rapportere dem ved å bruke "Rapporter problem" -funksjonen. Tilbakemeldingen din er svært verdsatt.',
        reportProb: () => "Rapporter problem",
        myVideos: () => "Mine videoer",
        newVideo: () => "Ny video",
        beingProcessed: () => "Behandles",
        videosQueue: () => "Videoer i køen",
      },
      wordCards: {
        flashCards: () => "Spørsmålskort",
        nextCard: () => "Neste kort klart",
        now: () => "Nå",
        today: () => "I dag",
        tomorrow: () => "I morgen",
        nextWeek: () => "Neste uke",
        soon: () => "Snart",
        total: () => "Totalt",
        level: () => "Nivå",
        add20: () => "Legg til 20 kort",
        reveal: () => "Vis svaret",
        addNote: () => "Legg til en notat",
        seen: () => "Sett",
        familiar: () => "Kjent",
        good: () => "God",
        known: () => "Gjenkjent",
      },
      browseContent: {
        menu: () => "Meny",
        home: () => "Startside",
        originalCont: () => "Originalt innhold",
        designOwn: () =>
          "Design din egen læringsreise. Opprett skreddersydde leksjoner som passer perfekt til dine unike læringspreferanser for maksimal effektivitet.",
        langContent: () => "LangFire innhold",
        myContent: () => "Mitt innhold",
        books: () => "Bøker",
        kidsBooks: () => "Barnebøker",
        audioBooks: () => "Lydbøker",
        wiki: () => "Wikipedia",
        discover: () => "Utforsk",
        search: () => "Søk",
        faves: () => "Favoritter",
        uploadContent: () => "Last opp leseinnhold",
        addText: () => "Legg til tekst her...",
        upload: () => "Last opp",
        chapter: () => "Kapittel",
        chapterTitle: () => "Kapittel tittel",
        description: () => "Beskrivelse",
      },
      contactFAQ: {
        rightsReserved: () => "©2024 Predicate Software Solutions Ltd.  Alle rettigheter forbeholdes.",
        aboutUs: () => "Om oss",
        help: () => "Hjelp",
        giveFeedback: () => "Gi tilbakemelding",
        submitFeedback: () => "Send inn tilbakemelding",
        priccing: () => "Priser",
        contact: () => "Kontakt",
      },
    },

    fi: {
      startPage: {
        intro1: () =>
          "Rekisteröidy tänään ja aloita matka uuden kielen oppimiseen! Se on täysin ilmaista. Sovelluksemme avulla voit tutustua uusien sanojen ja lauseiden maailmaan, mikä tekee oppimisesta helppoa ja hauskaa.",
        intro2: () =>
          "Seuraa edistymistäsi jokaisen oppimasi sanan ja lauseen kanssa. Tallenna saavutuksesi ja katso, kuinka monta uutta sanaa ja lausetta olet hallinnut. Sukella uuden sanaston ja ilmaisujen maailmaan ja koe oppimisen ilo ja palkitsevuus meidän sovelluksemme avulla.",
        intro3: () =>
          "Sovelluksemme on suunniteltu auttamaan sinua oppimaan uusi kieli mahdollisimman tehokkaasti. Hyödynnämme viimeisintä tutkimusta kielten oppimisessa auttaaksemme sinua oppimaan uuden kielen nopeasti.",
        signUp: () => "Rekisteröidy",
        username: () => "Käyttäjätunnus",
        email: () => "Sähköposti",
        password: () => "Salasana",
        confirmPw: () => "Vahvista salasana",
        logIn: () => "Kirjaudu sisään",
        welcomeBack: () => "Tervetuloa takaisin!",
        registerFree: () => "Liity ilmaiseksi",
      },
      profileSettings: {
        profile: () => "Profiili",
        editProfile: () => "Muokkaa profiilia",
        editAvatar: () => "Vaihda profiilikuvaa",
        deleteProfile: () => "Poista profiili",
        logOut: () => "Kirjaudu ulos",
        settings: () => "Asetukset",
        memberSince: () => "Jäsenestä asti",
        displayLang: () => "Sovelluksen kieli",
        done: () => "Valmis",
        save: () => "Tallenna",
        notif: () => "Ilmoitukset",
      },
      youtube: {
        // TODO: () =>add link here
        kindlyNote: () =>
          'Huomaa, että LangFire on tällä hetkellä alfa-vaiheessa, ja sen toiminnallisuus saattaa olla odotuksia vaatimattomampi. Lisäksi huomaa, että YouTube-transkriptiot eivät välttämättä aina ole saatavilla, tarkkoja tai täydellisiä. Jos kohtaat ongelmia, rohkaisemme sinua ilmoittamaan niistä käyttämällä "Ilmoita ongelmasta" -toimintoa. Palautteesi on erittäin arvokasta.',
        reportProb: () => "Ilmoita ongelmasta",
        myVideos: () => "Omat videoni",
        newVideo: () => "Uusi video",
        beingProcessed: () => "Käsittelyssä",
        videosQueue: () => "Videot jonossa",
      },
      wordCards: {
        flashCards: () => "Muistikortteja",
        nextCard: () => "Seuraava kortti valmis",
        now: () => "Nyt",
        today: () => "Tänään",
        tomorrow: () => "Huomenna",
        nextWeek: () => "Ensi viikolla",
        soon: () => "Pian",
        total: () => "Yhteensä",
        level: () => "Taso",
        add20: () => "Lisää 20 korttia",
        reveal: () => "Näytä vastaus",
        addNote: () => "Lisää muistiinpano",
        seen: () => "Nähty",
        familiar: () => "Tuttu",
        good: () => "Hyvä",
        known: () => "Tunnettu",
      },
      browseContent: {
        menu: () => "Valikko",
        home: () => "Etusivu",
        originalCont: () => "Alkuperäinen sisältö",
        designOwn: () =>
          "Suunnittele oma oppimispolkusi. Luo räätälöityjä oppitunteja, jotka sopivat täydellisesti ainutlaatuiseen oppimistyyliisi maksimaalisen tehokkuuden saavuttamiseksi.",
        langContent: () => "LangFire sisältö",
        myContent: () => "Oma sisältö",
        books: () => "Kirjat",
        kidsBooks: () => "Lastenkirjat",
        audioBooks: () => "Äänikirjat",
        wiki: () => "Wikipedia",
        discover: () => "Tutustu",
        search: () => "Haku",
        faves: () => "Suosikit",
        uploadContent: () => "Lataa luettavaa sisältöä",
        addText: () => "Lisää tekstiä tähän...",
        upload: () => "Lataa",
        chapter: () => "Luku",
        chapterTitle: () => "Luvun otsikko",
        description: () => "Kuvaus",
      },
      contactFAQ: {
        rightsReserved: () => "©2024 Predicate Software Solutions Ltd. Kaikki oikeudet pidätetään.",
        aboutUs: () => "Tietoa meistä",
        help: () => "Ohje",
        giveFeedback: () => "Anna palautetta",
        submitFeedback: () => "Lähetä palaute",
        priccing: () => "Hinnat",
        contact: () => "Yhteystiedot",
      },
    },

    ja: {
      startPage: {
        intro1: () =>
          "今日アカウントを作成して、新しい言語を学び始めましょう！完全無料です。私たちのアプリで新しい単語やフレーズの世界に飛び込んで、学ぶことが簡単で楽しくなります。",
        intro2: () =>
          "学んだ単語やフレーズごとの進捗状況を追跡します。進歩を保存し、習得した新しい単語の数や表現を確認することもできます。私たちのアプリを使って新しい言葉やフレーズを学ぶことの楽しさを体験してください。",
        intro3: () =>
          "私たちのアプリは、できるだけ効率的な方法で新しい言語を学ぶのに役立つよう設計されています。新しい言語の学習に最新の研究を活用して、新しい言語を速く学ぶのをサポートします。",
        signUp: () => "登録する",
        username: () => "ユーザー名",
        email: () => "メール",
        password: () => "パスワード",
        confirmPw: () => "パスワードを確認する",
        logIn: () => "ログイン",
        welcomeBack: () => "おかえりなさい！",
        registerFree: () => "無料で登録する",
      },
      profileSettings: {
        profile: () => "プロフィール",
        editProfile: () => "プロフィールを編集",
        editAvatar: () => "プロフィール写真を変更",
        deleteProfile: () => "プロフィールを削除",
        logOut: () => "ログアウト",
        settings: () => "設定",
        memberSince: () => "入会日",
        displayLang: () => "アプリ使用言語",
        done: () => "完了",
        save: () => "保存",
        notif: () => "通知",
      },
      youtube: {
        // TODO: () =>add link here
        kindlyNote: () =>
          "LangFireは現在アルファフェーズにあり、その機能はすべての期待に応えないかもしれませんので、ご了承ください。また、YouTubeのトランスクリプトが常に利用可能、正確、または完全であるとは限らないことに注意してください。問題が発生した場合は、「問題を報告する」機能を使用して報告してください。あなたのフィードバックは非常に貴重です。",
        reportProb: () => "問題を報告する",
        myVideos: () => "マイビデオ",
        newVideo: () => "新しいビデオ",
        beingProcessed: () => "処理中です",
        videosQueue: () => "キューに入っているビデオ",
      },
      wordCards: {
        flashCards: () => "フラッシュカード",
        nextCard: () => "次のカードが準備完了です",
        now: () => "今",
        today: () => "今日",
        tomorrow: () => "明日",
        nextWeek: () => "来週",
        soon: () => "間もなく",
        total: () => "合計",
        level: () => "レベル",
        add20: () => "20 枚のカードを追加する",
        reveal: () => "答えを見る",
        addNote: () => "ノートを追加する",
        seen: () => "見た",
        familiar: () => "馴染みのある",
        good: () => "良い",
        known: () => "知られている",
      },
      browseContent: {
        menu: () => "メニュー",
        home: () => "ホーム",
        originalCont: () => "オリジナルコンテンツ",
        designOwn: () =>
          "あなただけの学習の旅をデザインしてください。あなたのユニークな学習の好みに完璧に合ったカスタムレッスンを作成して、最大の効果を得てください。",
        langContent: () => "LangFireコンテンツ",
        myContent: () => "マイコンテンツ",
        books: () => "本",
        kidsBooks: () => "子供向けの本",
        audioBooks: () => "オーディオブック",
        wiki: () => "ウィキペディア",
        discover: () => "作品を探す",
        search: () => "検索",
        faves: () => "お気に入り",
        uploadContent: () => "読書コンテンツをアップロード",
        addText: () => "ここにテキストを入力...",
        upload: () => "アップロード",
        chapter: () => "章",
        chapterTitle: () => "章のタイトル",
        description: () => "説明",
      },
      contactFAQ: {
        rightsReserved: () => "©2024 Predicate Software Solutions Ltd. 全ての権利は留保されています。",
        aboutUs: () => "LangFireについて",
        help: () => "ヘルプ",
        giveFeedback: () => "ご意見・ご要望フォーム",
        submitFeedback: () => "入力フォームへすすむ",
        priccing: () => "料金",
        contact: () => "問い合わせ",
      },
    },

    ch: {
      startPage: {
        intro1: () =>
          "立即注册并开始学习新语言之旅！完全免费。通过我们的应用程序，您可以探索新单词和短语的世界，使学习变得简单而有趣。",
        intro2: () =>
          "跟踪您学习的每个单词和短语的进度。保存您的进度，并查看您已学习的新单词数量和表达方式。通过我们的应用程序学习新单词和短语的乐趣。",
        intro3: () =>
          "我们的应用程序旨在以最有效的方式帮助您学习一种新语言。我们利用最新的语言学习研究帮助您快速学习一种新语言。",
        signUp: () => "注册",
        username: () => "用户名",
        email: () => "电子邮件",
        password: () => "电子邮件",
        confirmPw: () => "确认密码",
        logIn: () => "登录",
        welcomeBack: () => "欢迎回来!",
        registerFree: () => "免费注册",
      },
      profileSettings: {
        profile: () => "个人资料",
        editProfile: () => "编辑个人资料",
        editAvatar: () => "更改头像",
        deleteProfile: () => "删除个人资料",
        logOut: () => "登出",
        settings: () => "设置",
        memberSince: () => "加入以来",
        displayLang: () => "显示语言",
        done: () => "完成",
        save: () => "保存",
        notif: () => "通知",
      },
      youtube: {
        // TODO: () =>add link here
        kindlyNote: () =>
          "请注意，LangFire目前处于alpha阶段，其功能可能不符合所有期望。此外，请注意，来自YouTube的转录可能并不总是可用、准确或完整。如果遇到任何问题，请使用“报告问题”功能进行报告。非常感谢您的反馈。",
        reportProb: () => "报告问题",
        myVideos: () => "我的视频",
        newVideo: () => "新视频",
        beingProcessed: () => "正在处理",
        videosQueue: () => "排队中的视频",
      },
      wordCards: {
        flashCards: () => "抽认卡",
        nextCard: () => "下一张卡片准备好了",
        now: () => "现在",
        today: () => "今天",
        tomorrow: () => "明天",
        nextWeek: () => "下个星期",
        soon: () => "很快",
        total: () => "总数",
        level: () => "等级",
        add20: () => "添加 20 张卡片",
        reveal: () => "显示答案",
        addNote: () => "添加备注",
        seen: () => "已看",
        familiar: () => "熟悉的",
        good: () => "好",
        known: () => "已知",
      },
      browseContent: {
        menu: () => "菜单",
        home: () => "主页",
        originalCont: () => "原创内容",
        designOwn: () => "设计您自己的学习之旅。创建与您独特的学习偏好完美匹配的定制课程，以实现最大的效果。",
        langContent: () => "LangFire 内容",
        myContent: () => "我的内容",
        books: () => "书",
        kidsBooks: () => "儿童书",
        audioBooks: () => "有声读物",
        wiki: () => "维基百科",
        discover: () => "探索",
        search: () => "搜索",
        faves: () => "收藏夹",
        uploadContent: () => "上传阅读内容",
        addText: () => "在这里添加文字...",
        upload: () => "上传",
        chapter: () => "章节",
        chapterTitle: () => "章标题",
        description: () => "描述",
      },
      contactFAQ: {
        rightsReserved: () => "©2024 Predicate Software Solutions Ltd. 版权所有.",
        aboutUs: () => "关于我们",
        help: () => "帮助",
        giveFeedback: () => "提供反馈",
        submitFeedback: () => "提交反馈",
        priccing: () => "价格",
        contact: () => "联系方式",
      },
    },

    ar: {
      startPage: {
        intro1: () =>
          "سجل اليوم وابدأ رحلتك في تعلم لغة جديدة! إنه مجاني تمامًا. مع تطبيقنا ، يمكنك استكشاف عالم كلمات وعبارات جديدة ، مما يجعل التعلم سهلاً وممتعًا.",
        intro2: () =>
          "تتبع رحلتك التعليمية مع كل كلمة وعبارة تتعلمها. استخدم ميزتنا لحفظ تقدمك، مما يسمح لك بالتفكير في مدى تقدمك. اكتشف الرضا بالتحكم في المفردات والعبارات الجديدة أثناء تصفح منصتنا، واستمتع بالتعلم السهل والممتع.",
        intro3: () =>
          "تم تصميم تطبيقنا لمساعدتك في تعلم لغة جديدة بأكثر الطرق فعالية ممكنة. نستخدم أحدث الأبحاث في تعلم اللغات لمساعدتك في تعلم لغة جديدة بسرعة.",
        signUp: () => "التسجيل",
        username: () => "اسم المستخدم",
        email: () => "بريد إلكتروني",
        password: () => "كلمة المرور",
        confirmPw: () => "تأكيد كلمة المرور",
        logIn: () => "تسجيل الدخول",
        welcomeBack: () => "مرحباً بعودتك!",
        registerFree: () => "سجل مجانا",
      },
      profileSettings: {
        profile: () => "الملف الشخصي",
        editProfile: () => "تعديل الملف الشخصي",
        editAvatar: () => "تغيير صورة الملف الشخصي",
        deleteProfile: () => "حذف الملف الشخصي",
        logOut: () => "تسجيل الخروج",
        settings: () => "الإعدادات",
        memberSince: () => "عضو منذ",
        displayLang: () => "لغة العرض",
        done: () => "تم",
        save: () => "حفظ",
        notif: () => "إشعارات",
      },
      youtube: {
        // TODO: () =>add link here
        kindlyNote: () =>
          'يرجى ملاحظة أن لانج فاير حاليًا في مرحلتها الألفا، وقد لا تلبي وظيفتها جميع التوقعات. بالإضافة إلى ذلك، يرجى ملاحظة أن النصوص المنقولة من يوتيوب قد لا تكون دائمًا متاحة أو دقيقة أو كاملة. إذا واجهت أي مشاكل، نشجعك على الإبلاغ عنها باستخدام ميزة "الإبلاغ عن مشكلة". تقديرنا الكبير لتعليقاتكم.',
        reportProb: () => "الإبلاغ عن مشكلة",
        myVideos: () => "فيديوهاتي",
        newVideo: () => "فيديو جديد",
        beingProcessed: () => "تم معالجته",
        videosQueue: () => "مقاطع الفيديو في قائمة الانتظار",
      },
      wordCards: {
        flashCards: () => "بطاقات استذكار",
        nextCard: () => "البطاقة التالية جاهزة",
        now: () => "الآن",
        today: () => "اليوم",
        tomorrow: () => "غدًا",
        nextWeek: () => "الأسبوع القادم",
        soon: () => "قريباً",
        total: () => "المجموع",
        level: () => "مستوى",
        add20: () => "إضافة 20 بطاقة",
        reveal: () => "إظهار الإجابة",
        addNote: () => "أضف ملاحظة",
        seen: () => "رُئي",
        familiar: () => "مألوف",
        good: () => "جيد",
        known: () => "معروف",
      },
      browseContent: {
        menu: () => "قائمة",
        home: () => "الصفحة الرئيسية",
        originalCont: () => "المحتوى الأصلي",
        designOwn: () =>
          "صمم رحلتك التعليمية الخاصة. أنشئ دروسًا مُخصصة تتناسب تمامًا مع تفضيلاتك التعليمية الفريدة للحصول على أقصى فعالية.",
        langContent: () => "محتوى LangFire",
        myContent: () => "المحتوى الخاص بي",
        books: () => "كتب",
        kidsBooks: () => "كتب الأطفال ",
        audioBooks: () => "كتب صوتية",
        wiki: () => "ويكيبيديا",
        discover: () => "اكتشف",
        search: () => "بحث",
        faves: () => "المفضلة",
        uploadContent: () => "رفع محتوى القراءة",
        addText: () => "أضف نصًا هنا...",
        upload: () => "تحميل",
        chapter: () => "الفصل",
        chapterTitle: () => "عنوان الفصل",
        description: () => "وصف",
      },
      contactFAQ: {
        rightsReserved: () => "©2024 Predicate Software Solutions Ltd. جميع الحقوق محفوظة.",
        aboutUs: () => "من نحن",
        help: () => "مساعدة",
        giveFeedback: () => "تقديم ردود الفعل",
        submitFeedback: () => "إرسال ملاحظات",
        priccing: () => "التسعيرة",
        contact: () => "اتصل بنا",
      },
    },
  };

  return { langs, getTranslation };
};
