// npm
import { ReactNode } from "react";
import styled from "styled-components";

type FlexProps = {
  children: ReactNode;
  col?: boolean;
  justify?: string;
  align?: string;
  content?: string;
  wrap?: boolean;
  gap?: string;
  wFull?: boolean;
  hFull?: boolean;
  height?: string;
};

export const Flex = ({
  children,
  col = false,
  justify = "center",
  align = "center",
  content = "normal",
  wrap = false,
  gap = "",
  wFull = false,
  hFull = false,
  height = "auto",
}: FlexProps) => {
  return (
    <StyledFlex
      $col={col}
      $justify={justify}
      $align={align}
      $content={content}
      $wrap={wrap}
      $gap={gap}
      $wFull={wFull}
      $hFull={hFull}
      $height={height}
    >
      {children}
    </StyledFlex>
  );
};

type StyledFlexProps = {
  children: React.ReactNode;
  $col: boolean;
  $justify: string;
  $align: string;
  $content: string;
  $wrap: boolean;

  $gap: string;
  $wFull: boolean;
  $hFull: boolean;
  $height: string;
};

const StyledFlex = styled.div<StyledFlexProps>`
  display: flex;
  flex-direction: ${({ $col }) => ($col ? "column" : "row")};
  justify-content: ${({ $justify }) => $justify};
  align-items: ${({ $align }) => $align};
  align-content: ${({ $content }) => $content};
  flex-wrap: ${({ $wrap }) => ($wrap ? "wrap" : "nowrap")};
  ${({ $gap }) => ($gap ? "gap: " + $gap + ";" : "")}
  ${({ $wFull }) => ($wFull ? "width: 100%;" : "")}
  ${({ $height }) => ($height ? "height: " + $height + ";" : "")}
  ${({ $hFull }) => ($hFull ? "height: 100%;" : "")}
`;
