import { create } from "zustand";

interface State {
  errors: {
    api?: string;
    email?: string;
    password?: string;
    username?: string;
    confirmPassword?: string;
  };
  validateLogin: (email: string, password: string) => boolean;
  validateSignup: (username: string, email: string, password: string, confirmPassword: string) => boolean;
  setErrors: ({ api, email, password }: { api?: string; email?: string; password?: string }) => void;
  clearErrors: () => void;
}

export const useValidationStore = create<State>()((set) => {
  const validateLogin = (email: string, password: string) => {
    const errors: State["errors"] = {};

    if (!email) {
      errors.email = "Email is required";
    } else if (email.length > 50) {
      errors.email = "Email must be less than 50 characters long";
    } else if (email.split("@").length !== 2) {
      errors.email = "Email is not valid";
    }

    if (!password) {
      errors.password = "Password is required";
    } else if (password.length < 8) {
      errors.password = "Password must be at least 8 characters long";
    } else if (password.length > 50) {
      errors.password = "Password must be less than 50 characters long";
    }

    if (Object.keys(errors).length > 0) {
      set({ errors });
      return false;
    }

    return true;
  };

  const validateSignup = (username: string, email: string, password: string, confirmPassword: string) => {
    const errors: State["errors"] = {};

    if (!username) {
      errors.username = "Username is required";
    } else if (!/^[a-zA-Z0-9]*$/.test(username)) {
      errors.username = "Username can only contain letters and numbers";
    } else if (username.length > 50) {
      errors.username = "Username must be less than 50 characters long";
    }

    if (!confirmPassword) {
      errors.confirmPassword = "Confirm Password is required";
    } else if (confirmPassword !== password) {
      errors.confirmPassword = "Passwords do not match";
    }

    if (Object.keys(errors).length > 0) {
      set({ errors });
      return false;
    }

    return validateLogin(email, password);
  };

  return {
    errors: {
      api: "",
      email: "",
      password: "",
    },
    setErrors: (newErrors) => {
      set((state) => ({
        errors: { ...state.errors, ...newErrors },
      }));
    },
    clearErrors: () => {
      set({ errors: { api: "", email: "", password: "" } });
    },
    validateLogin,
    validateSignup,
  };
});
