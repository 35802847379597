// npm
import { create } from "zustand";

// models
import { User } from "models/auth";

interface State {
  user: User | null;
  homeLang: string;
  targetLang: string;
  userWords: any[];
  setHomeLang: (lang: string) => void;
  setTargetLang: (lang: string) => void;
  setUser: (user: any) => void;
  logout: () => void;
  setUserWords: (words: any[]) => void;
}

export const useAppStore = create<State>()((set) => {
  const setHomeLang = (lang: string) => {
    localStorage.setItem("homeLang", lang);
    set({ homeLang: lang });
  };

  const setTargetLang = (lang: string) => {
    localStorage.setItem("targetLang", lang);
    set({ targetLang: lang });
  };

  const setUser = (user: any) => {
    localStorage.setItem("user", JSON.stringify(user));
    set({ user });
  };

  const logout = () => {
    localStorage.removeItem("user");
    set({ user: null, userWords: [] });
  };

  return {
    user: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")!) : null,
    homeLang: localStorage.getItem("homeLang") || "en",
    targetLang: localStorage.getItem("targetLang") || "en",
    userWords: [],
    setHomeLang,
    setTargetLang,
    setUser,
    logout,
    setUserWords: (words: any[]) => set({ userWords: words }),
  };
});
