// npm
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

// hooks
import { useUIStore } from "store/uiStore";

// components
import { Card } from "components/browse";

// types
import { SubCategory } from "models/content";
import { css } from "css";

type TrayProps = {
  subCat: SubCategory | undefined;
};

export const Tray = ({ subCat }: TrayProps) => {
  // hooks
  const { selectedSubCategory, setSelectedSubCategory } = useUIStore();
  const navigate = useNavigate();

  // methods
  const selectContent = (id: number) => {
    setSelectedSubCategory(0);
    navigate(`/reader/${id}`);
  };

  return (
    <>
      {!!selectedSubCategory && <Backdrop onClick={() => setSelectedSubCategory(0)} />}
      <Container $isOpen={selectedSubCategory !== 0}>
        {subCat?.contents?.map((c) => (
          <Card key={c.title} title={c.title} subTitle={c.description} onClick={() => selectContent(c.id)} />
        ))}
      </Container>
    </>
  );
};

type ContainerProps = {
  $isOpen: boolean;
};

const Container = styled.div<ContainerProps>`
  ${css.abs}
  ${css.pad.xl}
  ${css.z(2)}

  display: none;
  width: 100%;
  height: 80%;
  bottom: -100%;
  left: 0;

  background: linear-gradient(0deg, #2a2d36, #222431);
  transition: all 0.3s ease;

  ${({ $isOpen }) => {
    if ($isOpen) {
      return `
        ${css.flex({ j: "c", aI: "fs", aC: "fs", wrap: 1, gap: 40 })}
        bottom: 0;
      `;
    }
  }}
`;

const Backdrop = styled.div`
  ${css.fix}
  ${css.z(1)}

  background: #00000088;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;
