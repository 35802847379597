// npm
import { useEffect } from "react";
import styled, { ThemeProvider } from "styled-components";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

// views
import { LoginView } from "views/auth/Login";
import { SignupView } from "views/auth/Signup";
import { BrowseView } from "views/Browse";
import { ReadingView } from "views/Reading";

// theme
import { baseTheme } from "theme";

// hooks
import { useUIStore } from "store/uiStore";

// components
import { Navbar } from "components";

// css
import { css } from "css";

// data
export const baseApi = "http://localhost:8085";
export const baseWS = "ws://localhost:8085";

export const App = () => {
  // hooks
  const { watchResize } = useUIStore();

  // watchters
  useEffect(() => {
    window.addEventListener("resize", watchResize);
    return () => {
      window.removeEventListener("resize", watchResize);
    };
  }, [watchResize]);

  // JSX
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Navbar />,
      children: [
        {
          index: true,
          element: <BrowseView />,
        },
        {
          path: "/reader/:id",
          element: <ReadingView />,
        },
        {
          path: "/login",
          element: <LoginView />,
        },
        {
          path: "/signup",
          element: <SignupView />,
        },
      ],
    },
  ]);

  return (
    <ThemeProvider theme={baseTheme}>
      <Body>
        <RouterProvider router={router} />
      </Body>
    </ThemeProvider>
  );
};

const Body = styled.div`
  ${css.hide.oF}
  color: ${({ theme }) => theme.colors.lining};
  ${({ theme }) => theme.colors.gradientBG};
`;
