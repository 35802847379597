// npm
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

// hooks
import { useUIStore } from "store/uiStore";

// components
import { Flex } from "components/utility";
import { Card } from "components/browse";

// css
import { css } from "css";

// types
import { Category } from "models/content";

type CategoryTSXProps = {
  category: Category;
};

export const CategoryTSX = ({ category }: CategoryTSXProps) => {
  // hooks
  const { isMobile, selectedSubCategory, setSelectedSubCategory } = useUIStore();
  const navigate = useNavigate();

  // computed
  const isCollapsed =
    category.subCategories.find((x) => x.id === selectedSubCategory)?.contents?.length === 0 ||
    !category.subCategories?.map((x) => x.id).includes(selectedSubCategory);

  // methods
  const selectContent = (id: number) => {
    setSelectedSubCategory(0);
    navigate(`/reader/${id}`);
  };

  // markup
  const contentMarkup = () => {
    const subCategory = category.subCategories.find((sc) => sc.id === selectedSubCategory);

    if (!subCategory?.contents) return null;

    return subCategory.contents?.map((c) => (
      <Card key={c.title} title={c.title} subTitle={c.description} size={"small"} onClick={() => selectContent(c.id)} />
    ));
  };

  return (
    <>
      <ContentCategoryTitle>{category.title}</ContentCategoryTitle>
      <Flex justify={"start"} gap={"40px"}>
        {category.subCategories.map((sc) => (
          <Card
            key={sc.title}
            title={sc.title}
            subTitle={sc.description}
            onClick={() => setSelectedSubCategory(sc.id)}
            isSelected={selectedSubCategory === sc.id}
          />
        ))}
      </Flex>
      {!isMobile && <ContentContainer $isCollapsed={isCollapsed}>{contentMarkup()}</ContentContainer>}
    </>
  );
};

const ContentCategoryTitle = styled.div`
  ${css.pureText}

  color: ${({ theme }) => theme.colors.lining};

  font-size: 20px;
  font-weight: 600;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    font-size: 30px;
  }
`;

type ContentContainerProps = {
  $isCollapsed: boolean;
};

const ContentContainer = styled.div<ContentContainerProps>`
  ${css.flex({ j: "fs", gap: 40 })}
  ${css.scroll}

  margin-top: 40px;
  transition: all 0.4s ease;
  height: 260px;
  width: 100%;
  overflow-y: hidden;
  overflow-x: auto;

  ${({ $isCollapsed }) => $isCollapsed && "height: 0px;"}
`;
