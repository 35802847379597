// npm
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { MoonLoader } from "react-spinners";

// hooks
import { useUIStore } from "store/uiStore";
import { useReaderStore } from "store/readerStore";
import { useQuery } from "hooks/query";

// components
import { Flex } from "components/utility";
import { css } from "css";

// types
type PageListProps = {
  setTabSelected: (tab: string) => void;
};

export const PageList = ({ setTabSelected }: PageListProps) => {
  // hooks
  const { id } = useParams();
  const { reader } = useReaderStore();
  const { isLoadingReading } = useUIStore();
  const { getReader } = useQuery();

  return (
    <PageListContainer>
      {!isLoadingReading ? (
        <>
          {reader.pages.map((page, i) => (
            <PageTitle
              key={page.id}
              onClick={() => {
                if (id) getReader(id, page.id);
                setTabSelected("text");
              }}
            >
              {i + 1 + ": " + page.title[0].toUpperCase() + page.title.slice(1)}
            </PageTitle>
          ))}
        </>
      ) : (
        <Flex hFull>
          <MoonLoader color={"#fcc852"} loading={isLoadingReading} />
        </Flex>
      )}
    </PageListContainer>
  );
};

const PageListContainer = styled.div`
  ${css.flex({ j: "fs", aI: "c", dir: "col", gap: 10 })}
  ${css.padY.xs}
  ${css.padX.l}

  max-width: 800px;
  width: 800px;
  overflow-y: auto;
  height: 75%;
  background: linear-gradient(0deg, #2a2d36, #222431);
  border-radius: 20px;

  @media (max-width: 1268px) {
    width: 500px;
    max-width: 500px;
  }

  @media (max-width: 940px) {
    width: 400px;
    max-width: 400px;
  }
  @media (max-width: 840px) {
    width: 300px;
    max-width: 300px;
  }
  @media (max-width: 768px) {
    width: 100%;
    max-width: 100%;
  }
`;

const PageTitle = styled.div`
  ${css.flex({ j: "fs", aI: "c" })}
  ${css.copy}
  ${css.padX.s}
  ${css.pureText}
  ${css.point}

  text-align: left;
  width: 100%;
  height: 50px;
  border-radius: 8px;

  &:hover {
    background: linear-gradient(90deg, #fcc85277, #ff3d9f77);
  }
`;
