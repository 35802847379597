// npm
import { Link } from "react-router-dom";
import styled from "styled-components";

// hooks
import { useAppStore } from "store";
import { useUIStore } from "store/uiStore";
import { useUILangs } from "hooks/uiLangs";

// data
import { links, adminLinks } from "./links";

// css
import { css } from "css";

export const DesktopLinks = () => {
  // hooks
  const { user } = useAppStore();
  const { closeMenus } = useUIStore();
  const { getTranslation } = useUILangs();

  return (
    <>
      {links.map((link) => (
        <StyledLink
          key={link.name}
          onClick={() => {
            closeMenus();
          }}
          to={link.to}
        >
          {link.lookups ? getTranslation(...link.lookups) : link.name}
        </StyledLink>
      ))}

      {user?.isAdmin &&
        adminLinks.map((link) => (
          <StyledLink
            key={link.name}
            onClick={() => {
              closeMenus();
            }}
            to={link.to}
          >
            {link.lookups ? getTranslation(...link.lookups) : link.name}
          </StyledLink>
        ))}
    </>
  );
};

const StyledLink = styled(Link)`
  ${css.pureText}

  font-size: 16px;
`;
