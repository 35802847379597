// npm
import axios, { isAxiosError } from "axios";

// hooks
import { useAppStore } from "store";
import { useContentStore } from "store/contentStore";
import { useReaderStore } from "store/readerStore";

// models
import { ErrorsResponse } from "models/error";

//const apiUrl = process.env.REACT_APP_API_URL;
//const apiUrl = "http://localhost:8080";
const apiUrl = "https://api.langfire.io";

export const useQuery = () => {
  const { setUser, targetLang, setUserWords, logout } = useAppStore();
  const { setDefaultContent } = useContentStore();
  const { setReader } = useReaderStore();

  const getFirstError = (errors: ErrorsResponse) => {
    return errors.errors[0].error.message;
  };

  const makeConfig = () => {
    const token = JSON.parse(localStorage.getItem("user") as string)?.token || "";
    if (!token) {
      logout();
      return { ok: false };
    }
    return {
      ok: true,
      config: {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    };
  };

  const login = async (form: { email: string; password: string }): Promise<ErrorsResponse> => {
    try {
      const res = await axios.post(apiUrl + "/api/v1/login", form);
      setUser(res.data);
      return { message: "", status: 0, errors: [] };
    } catch (error) {
      if (isAxiosError(error) && error.response) {
        return error.response.data;
      } else {
        return { message: "An error occurred", status: 0, errors: [] };
      }
    }
  };

  const signup = async (form: {
    username: string;
    email: string;
    password: string;
    confirmPassword: string;
  }): Promise<ErrorsResponse> => {
    try {
      const res = await axios.post(apiUrl + "/api/v1/signup", form);
      setUser(res.data);
      return { message: "", status: 0, errors: [] };
    } catch (error) {
      if (isAxiosError(error) && error.response) {
        return error.response.data;
      } else {
        return { message: "An error occurred", status: 0, errors: [] };
      }
    }
  };

  const getContent = async (): Promise<ErrorsResponse> => {
    try {
      const res = await axios.get(apiUrl + "/api/v1/content/" + targetLang);
      setDefaultContent(res.data.categories);
      return { message: "", status: 0, errors: [] };
    } catch (error) {
      if (isAxiosError(error) && error.response) {
        return error.response.data;
      } else {
        return { message: "An error occurred", status: 0, errors: [] };
      }
    }
  };

  const getReader = async (id: string, pageId: number): Promise<ErrorsResponse> => {
    try {
      const res = await axios.get(apiUrl + "/api/v1/content/" + targetLang + "/" + id + "/" + pageId);
      setReader(res.data);
      return { message: "", status: 0, errors: [] };
    } catch (error) {
      if (isAxiosError(error) && error.response) {
        return error.response.data;
      } else {
        return { message: "An error occurred", status: 0, errors: [] };
      }
    }
  };

  const getUserWords = async (): Promise<ErrorsResponse> => {
    try {
      const { config, ok } = makeConfig();
      if (!ok) return { message: "An error occurred", status: 0, errors: [] };
      const res = await axios.get(apiUrl + "/api/v1/user-word", config);
      setUserWords(res.data);
      return { message: "", status: 0, errors: [] };
    } catch (error) {
      if (isAxiosError(error) && error.response) {
        return error.response.data;
      } else {
        return { message: "An error occurred", status: 0, errors: [] };
      }
    }
  };

  const saveUserWord = async (lowercase: string, level: number, callback: () => void): Promise<ErrorsResponse> => {
    try {
      const { config, ok } = makeConfig();
      if (!ok) return { message: "An error occurred", status: 0, errors: [] };
      await axios.post(apiUrl + "/api/v1/user-word", { lowercase, level, language: targetLang }, config);
      callback();
      return { message: "", status: 0, errors: [] };
    } catch (error) {
      if (isAxiosError(error) && error.response) {
        return error.response.data;
      } else {
        return { message: "An error occurred", status: 0, errors: [] };
      }
    }
  };

  return { login, signup, getFirstError, getContent, getReader, getUserWords, saveUserWord };
};
