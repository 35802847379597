// npm
import styled from "styled-components";
import { Outlet } from "react-router-dom";
import { Toaster } from "react-hot-toast";

// hooks
import { useUIStore } from "store/uiStore";

// components
import { Flex } from "components/utility";
import { DesktopLinks } from "./DesktopLinks";
import { LanguageChip } from "./LanguageChip";
import { AccountButtons } from "./AccountButtons";
import { BurgerMenu } from "./BurgerMenu";

// css
import { css } from "css";

export const Navbar = () => {
  // hooks
  const { isMobile, toast } = useUIStore();

  return (
    <>
      <StyledNavbar>
        <LeftBar>
          <Flex col gap={"4px"}>
            <Logo onClick={() => toast("langfire!")}>LF 🔥</Logo>
            <Version>PRE ALPHA</Version>
          </Flex>

          {!isMobile && <DesktopLinks />}
        </LeftBar>

        <RightBar>
          <LanguageChip name={"home"} />
          <LanguageChip name={"target"} />
          {!isMobile && <AccountButtons />}
          {isMobile && <BurgerMenu />}
        </RightBar>
      </StyledNavbar>
      <StyledOutlet $isMobile={isMobile}>
        <Outlet />
      </StyledOutlet>

      <Toaster />
    </>
  );
};

type StyledOutletProps = {
  $isMobile: boolean;
};

const StyledOutlet = styled.div<StyledOutletProps>`
  ${css.hide.oF}

  width: 100%;
  height: calc(100vh - 70px);
  ${({ $isMobile }: StyledOutletProps) => ($isMobile ? "overflow-y: scroll;" : "")}
`;

const StyledNavbar = styled.div`
  ${css.flex({ j: "sb" })};
  ${css.dropShadow};

  width: 100%;
  height: 70px;
`;

const LeftBar = styled.div`
  ${css.flex({ j: "fs", aI: "c", gap: 24 })}
  ${css.pureText}

  padding-left: 24px;
`;

const RightBar = styled.div`
  ${css.flex({ j: "fe", aI: "c", gap: 24 })}

  padding-right: 24px;
`;

const Logo = styled.div`
  ${css.pureText}
  ${css.point}

  font-size: 24px;
  min-width: 65px;
`;

const Version = styled.div`
  ${css.pureText}

  font-size: 10px;
`;
