// npm
import { ChangeEvent, FormEvent } from "react";
import styled from "styled-components";
import { MoonLoader } from "react-spinners";

// hooks
import { useUIStore } from "store/uiStore";
import { useValidationStore } from "store/validationStore";

// components
import { Button, Input } from "components/shared";
import { Flex } from "components/utility";

// css
import { css } from "css";

// theme
import { colors } from "theme/color";

// types
import { Mobile } from "types/styled-components";

type AuthFormProps = {
  data: { email: string; password: string; username?: string; confirmPassword?: string };
  isLoading: boolean;
  onInputChange: (e: ChangeEvent<HTMLInputElement>, name: string) => void;
  onSubmit: () => void;
};

export const AuthForm = ({ data, isLoading, onInputChange, onSubmit }: AuthFormProps) => {
  // hooks
  const { isMobile, isShortScreen, isDesktop, sHeight } = useUIStore();
  const { errors } = useValidationStore();

  // methods
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSubmit();
  };

  // computed
  const isLG = data.username !== undefined && !isMobile ? sHeight >= 900 : isDesktop;
  const isHeadline = data.username !== undefined ? sHeight >= 900 : !isShortScreen;

  return (
    <FormWrapper $isMobile={isMobile}>
      <Flex col gap={isDesktop ? "50px" : ""}>
        {isHeadline && (
          <Headline $isMobile={isMobile}>
            {data.username !== undefined ? "Let's get started" : "Nice to see you again"}
          </Headline>
        )}
        <Form onSubmit={handleSubmit}>
          {data.username !== undefined && (
            <Flex wFull col gap={"10px"}>
              <Label>Username</Label>
              <Input
                size={!isLG ? "md" : "lg"}
                value={data.username}
                onChange={(e) => onInputChange(e, "username")}
                error={errors.username}
              />
            </Flex>
          )}
          <Flex wFull col gap={"10px"}>
            <Label>Email</Label>
            <Input
              size={!isLG ? "md" : "lg"}
              value={data.email}
              onChange={(e) => onInputChange(e, "email")}
              error={errors.email}
            />
          </Flex>

          <Flex wFull col gap={"10px"}>
            <Label>Password</Label>
            <Input
              size={!isLG ? "md" : "lg"}
              type="password"
              value={data.password}
              onChange={(e) => onInputChange(e, "password")}
              error={errors.password}
            />
          </Flex>
          {data.confirmPassword !== undefined && (
            <Flex wFull col gap={"10px"}>
              <Label>Confirm Password</Label>
              <Input
                size={!isLG ? "md" : "lg"}
                type="password"
                value={data.confirmPassword}
                onChange={(e) => onInputChange(e, "confirmPassword")}
                error={errors.confirmPassword}
              />
            </Flex>
          )}
          <Actions>
            {errors.api && <APIError>{errors.api}</APIError>}
            <Button type="submit">
              {isLoading ? (
                <MoonLoader size={20} color={colors.base.lining} />
              ) : data.username !== undefined ? (
                "Signup"
              ) : (
                "Login"
              )}
            </Button>
          </Actions>
        </Form>
      </Flex>
    </FormWrapper>
  );
};

const FormWrapper = styled.div<Mobile>`
  padding: 20px;
  width: 700px;
  max-width: 100%;

  ${({ $isMobile }: Mobile) => !$isMobile && `max-height: 100%; overflow-y: auto;`}
`;

const Headline = styled.div<Mobile>`
  ${css.pureText}
  ${css.copy}

  ${({ $isMobile }: Mobile) => ($isMobile ? `font-size: 32px; margin-bottom: 20px;` : `font-size: 40px; `)}
`;

const Form = styled.form`
  ${css.flex({ j: "c", aI: "c", dir: "col", gap: 20 })}

  width: 400px;
  max-width: 100%;
  max-height: 100%;
`;

const Label = styled.div`
  ${css.pureText}
  ${css.textLeft}

  color: ${({ theme }) => theme.colors.dim};

  width: 100%;
  font-size: 24px;
  font-weight: 600;
  padding-left: 20px;
`;

const Actions = styled.div`
  ${css.flex({ j: "c", aI: "c", dir: "col", gap: 10 })}

  width: 100%;
`;

const APIError = styled.div`
  color: ${({ theme }) => theme.colors.error};

  font-size: 18px;
`;
