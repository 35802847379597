const baseColors = {
  primary: "#ff0000",
  background: {
    to: "#1d2028",
    from: "#202430",
  },
  lining: "#c6c8d1",
  dim: "#94949c",
  gradient: "linear-gradient(180deg, #202430 0%, #1d2028 60%)",
  gradientBG: "background: linear-gradient(180deg, #202430 0%, #1d2028 60%)",
  error: "#ea2828",
  glow: {
    sm: ` box-shadow:
      5px 5px 20px 0px #fcc85255,
      -5px -5px 20px 0px #ff3d9f55;`,
    md: `box-shadow:
      2px 2px 5px 1px #fcc85255,
      -2px -2px 5px 1px #ff3d9f55;`,
  },
};

export const colors = {
  base: baseColors,
};
