// npm
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Menu } from "@mui/icons-material";

// hooks
import { useAppStore } from "store";
import { useUIStore } from "store/uiStore";
import { useUILangs } from "hooks/uiLangs";

// data
import { links, adminLinks } from "./links";

// css
import { css } from "css";

export const BurgerMenu = () => {
  // hooks
  const { user, logout } = useAppStore();
  const { isBurgerMenuOpen, setIsBurgerMenuOpen, closeMenus } = useUIStore();
  const { getTranslation } = useUILangs();

  return (
    <MenuWrapper
      onClick={(e) => {
        e.stopPropagation();
        closeMenus();
        setIsBurgerMenuOpen(!isBurgerMenuOpen);
      }}
    >
      <Menu />
      {isBurgerMenuOpen && (
        <StyledMenu>
          {links.map((link) => (
            <MenuItem key={link.name} onClick={() => setIsBurgerMenuOpen(false)}>
              <StyledLink
                onClick={() => {
                  setIsBurgerMenuOpen(false);
                }}
                to={link.to}
              >
                {link.lookups ? getTranslation(...link.lookups) : link.name}
              </StyledLink>
            </MenuItem>
          ))}

          {user?.isAdmin &&
            adminLinks.map((link) => (
              <MenuItem key={link.name} onClick={() => setIsBurgerMenuOpen(false)}>
                <StyledLink
                  onClick={() => {
                    setIsBurgerMenuOpen(false);
                  }}
                  to={link.to}
                >
                  {link.lookups ? getTranslation(...link.lookups) : link.name}
                </StyledLink>
              </MenuItem>
            ))}
          {user?.token && (
            <MenuItem onClick={() => setIsBurgerMenuOpen(false)}>
              <StyledLink to="/profile">Profile</StyledLink>
            </MenuItem>
          )}
          {user?.token && (
            <MenuItem
              onClick={() => {
                setIsBurgerMenuOpen(false);
                logout();
              }}
            >
              Logout
            </MenuItem>
          )}
          {!user?.token && (
            <>
              <MenuItem onClick={() => setIsBurgerMenuOpen(false)}>
                <StyledLink to="/login">Login</StyledLink>
              </MenuItem>
              <MenuItem onClick={() => setIsBurgerMenuOpen(false)}>
                <StyledLink to="/signup">Signup</StyledLink>
              </MenuItem>
            </>
          )}
        </StyledMenu>
      )}
    </MenuWrapper>
  );
};

const MenuWrapper = styled.div`
  ${css.rel}
  ${css.flex({ j: "c", aI: "c" })};
  ${css.pad.s}
  ${css.point}

  ${({ theme }) => theme.borders.radiusNone};

  &:hover {
    background: linear-gradient(90deg, #fcc85277, #ff3d9f77);
  }
`;

const StyledMenu = styled.div`
  ${css.abs}
  ${css.flex({ j: "c", aI: "c", dir: "col" })};
  ${css.pad.s}
  ${css.z(1)}

  ${({ theme }) => theme.colors.gradientBG};
  ${({ theme }) => theme.borders.thin};

  top: 100%;
  right: 0;
  width: 180px;
`;

const MenuItem = styled.div`
  ${css.flex({ j: "c", aI: "c" })};
  ${css.point}
  ${css.textCenter}

  ${({ theme }) => theme.borders.radiusNone};

  width: 100%;
  height: 35px;

  &:hover {
    background-color: #101220;
  }
`;

const StyledLink = styled(Link)`
  ${css.flex({ j: "c", aI: "c" })};
  ${css.pureText}

  font-size: 16px;
  width: 100%;
  height: 100%;
`;
