// npm
import { useNavigate } from "react-router-dom";

// hooks
import { useAppStore } from "store";
import { useUIStore } from "store/uiStore";

// components
import { Button } from "components/shared";
import { ProfileChip } from "./ProfileChip";

export const AccountButtons = () => {
  // hooks
  const navigate = useNavigate();
  const { user } = useAppStore();
  const { closeMenus } = useUIStore();

  return (
    <>
      {user?.token && <ProfileChip />}
      {!user?.token && (
        <>
          <Button
            onClick={() => {
              // TODO: is this needed?
              closeMenus();
              navigate("/login");
            }}
            variant={"secondary"}
          >
            Login
          </Button>
          <Button
            onClick={() => {
              // TODO: is this needed?
              closeMenus();
              navigate("/signup");
            }}
          >
            Signup
          </Button>{" "}
        </>
      )}
    </>
  );
};
