// npm
import { create } from "zustand";

// models
import { Category } from "models/content";

interface State {
  defaultContent: Category[];
  setDefaultContent: (content: Category[]) => void;
}

export const useContentStore = create<State>()((set) => {
  return {
    defaultContent: [],
    setDefaultContent: (content: Category[]) => {
      set({ defaultContent: content });
    },
  };
});
