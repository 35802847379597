// npm
import { create } from "zustand";

// models
import { Token } from "models/token";
import { Page, Reader } from "models/content";

interface State {
  reader: Reader;
  word: Token;
  selectedIndex: number | null;
  setReader: (reader: Reader) => void;
  setWord: (word: Token) => void;
  setSelectedIndex: (index: number | null) => void;
}

export const useReaderStore = create<State>()((set) => {
  return {
    reader: { selectedPage: { pageType: "" }, tokens: [] as Token[], pages: [] as Page[] } as Reader,
    word: {} as Token,
    selectedIndex: 0,
    setWord: (word: Token) => set({ word }),
    setSelectedIndex: (selectedIndex: number | null) => set({ selectedIndex }),
    setReader: (reader: Reader) => {
      set({ reader });
    },
  };
});
