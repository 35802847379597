// npm
import { useState, ChangeEvent } from "react";
import { useNavigate } from "react-router-dom";

// hooks
import { useQuery } from "hooks/query";
import { useUIStore } from "store/uiStore";
import { useValidationStore } from "store/validationStore";

// components
import { Flex } from "components/utility";
import { LoginCopy } from "components/copy";
import { AuthForm } from "components/auth/AuthForm";
import { VerticalBar } from "components/shared";

export const LoginView = () => {
  // hooks
  const navigate = useNavigate();
  const { login, getFirstError } = useQuery();
  const { isMobile } = useUIStore();
  const { validateLogin, clearErrors, setErrors } = useValidationStore();

  // state
  const [isLoading, setIsLoading] = useState(false);
  const [form, setForm] = useState({ email: "", password: "" });

  // methods
  const handleInputChange = (e: ChangeEvent<HTMLInputElement>, name: string) => {
    setForm((prev) => ({ ...prev, [name]: e.target.value }));
  };

  const submit = async () => {
    clearErrors();
    if (!validateLogin(form.email, form.password)) return;

    setIsLoading(true);
    const errors = await login(form);
    setIsLoading(false);

    if (errors.message) {
      setErrors({ api: getFirstError(errors) });
      return;
    }

    navigate("/");
  };

  return (
    <Flex hFull justify={isMobile ? "start" : "space-evenly"} align={isMobile ? "center" : "center"} col={isMobile}>
      {!isMobile && <LoginCopy />}
      {!isMobile && <VerticalBar />}
      <AuthForm data={form} isLoading={isLoading} onInputChange={handleInputChange} onSubmit={submit} />
      {isMobile && <LoginCopy />}
    </Flex>
  );
};
