// npm
import { useEffect, useState } from "react";
import styled from "styled-components";

// hooks
import { useUIStore } from "store/uiStore";
import { useQuery } from "hooks/query";
import { useContentStore } from "store/contentStore";

// components
import { Tray, CategoryTSX } from "components/browse";

// css
import { css } from "css";

// types
import { SubCategory } from "models/content";

export const BrowseView = () => {
  // hooks
  const { isMobile, selectedSubCategory } = useUIStore();
  const { getContent } = useQuery();
  const { defaultContent } = useContentStore();

  // state
  const [subCat, setSubCat] = useState<SubCategory | undefined>();

  // watchers
  useEffect(() => {
    defaultContent.forEach((cat) => {
      cat.subCategories.forEach((sc) => sc.id === selectedSubCategory && setSubCat(sc));
    });
  }, [selectedSubCategory, defaultContent]);

  // lifecycle
  useEffect(() => {
    getContent();
  }, []);

  return (
    <Browse>
      {defaultContent.map((category) => (
        <CategoryTSX key={category.title} category={category} />
      ))}
      {isMobile && <Tray subCat={subCat} />}
    </Browse>
  );
};

const Browse = styled.div`
  ${css.pad.xl}
  ${css.scroll}

  overflow-x: auto;
  height: 100%;
  max-width: 1400px;
`;
