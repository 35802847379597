// npm
import { StrictMode } from "react";
import ReactDOM from "react-dom/client";

// css
import "./index.css";

// components
import { App } from "./App";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <StrictMode>
    <App />
  </StrictMode>,
);
