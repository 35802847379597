type DesktopLink = {
  name: string;
  to: string;
  lookups?: string[];
};

export const links: DesktopLink[] = [
  { name: "Home", to: "/", lookups: ["browseContent", "home"] },
  { name: "Flash Cards", to: "/flashcards", lookups: ["wordCards", "flashCards"] },
  { name: "YouTube", to: "/youtube" },
];

export const adminLinks: DesktopLink[] = [
  { name: "Uploader", to: "/uploader" },
  { name: "Queue", to: "/queue" },
];
