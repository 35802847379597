import { create } from "zustand";

import toast from "react-hot-toast";

const mobileW = 992;
const shortH = 600;

interface State {
  sHeight: number;
  sWidth: number;
  isMobile: boolean;
  isShortScreen: boolean;
  isDesktop: boolean;
  isMenuOpen: { [key: string]: boolean };
  isBurgerMenuOpen: boolean;
  selectedSubCategory: number;
  isLoadingReading: boolean;
  watchResize: () => void;
  setIsMenuOpen: (name: string, isOpen: boolean) => void;
  setIsBurgerMenuOpen: (isOpen: boolean) => void;
  closeMenus: () => void;
  toast: (message: string) => void;
  setSelectedSubCategory: (id: number) => void;
  setIsLoadingReading: (isLoadingReading: boolean) => void;
}
export const useUIStore = create<State>()((set, get) => ({
  sHeight: window.innerHeight,
  sWidth: window.innerWidth,
  isMobile: window.innerWidth < mobileW,
  isShortScreen: window.innerHeight < shortH,
  isDesktop: window.innerWidth >= mobileW && window.innerHeight >= shortH,
  isMenuOpen: { target: false, home: false, profile: false },
  isBurgerMenuOpen: false,
  selectedSubCategory: 0,
  isLoadingReading: false,

  watchResize: () => {
    if (window.innerWidth < mobileW) set({ isMobile: true });
    else set({ isMobile: false });

    if (window.innerHeight < 600) set({ isShortScreen: true });
    else set({ isShortScreen: false });

    if (window.innerWidth >= mobileW && window.innerHeight >= shortH) set({ isDesktop: true });
    else set({ isDesktop: false });

    set({ sHeight: window.innerHeight, sWidth: window.innerWidth });
  },

  setIsMenuOpen: (name, isOpen) => {
    const updatedMenus = Object.keys(get().isMenuOpen).reduce(
      (acc, menuName) => {
        acc[menuName] = menuName === name ? isOpen : false;
        return acc;
      },
      {} as { [key: string]: boolean },
    );

    set({ isMenuOpen: updatedMenus });
  },

  setIsBurgerMenuOpen: (isOpen) => {
    set({ isBurgerMenuOpen: isOpen });
  },

  closeMenus: () => set({ isMenuOpen: { target: false, home: false, profile: false } }),

  toast: (message: string) => {
    toast(message, {
      duration: 4000,
      position: "bottom-right",
      style: {
        background: "#202430",
        color: "#c6c8d1",
        padding: "8px",
      },
    });
  },

  setSelectedSubCategory: (id) => set({ selectedSubCategory: id }),

  setIsLoadingReading: (isLoadingReading) => set({ isLoadingReading }),
}));
