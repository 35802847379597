// npm
import styled from "styled-components";

// css
import { css } from "css";

// types
type ButtonProps = {
  type?: "button" | "submit" | "reset";
  children: React.ReactNode;
  onClick?: () => void;
  size?: "small" | "medium" | "large";
  variant?: "primary" | "secondary";
};

export const Button = ({ type = "button", children, onClick, size = "medium", variant = "primary" }: ButtonProps) => {
  return (
    <StlyedButton type={type} $variant={variant} onClick={onClick} $size={size}>
      {children}
    </StlyedButton>
  );
};

type StyledButtonProps = {
  $size: "small" | "medium" | "large";
  $variant?: "primary" | "secondary";
};

const variant = {
  primary: `
      color: white;
      background: linear-gradient(90deg, #fcc852, #ff3d9f);
      &:hover {
      background: linear-gradient(90deg, #daa630, #dd1b7d);
      }`,
  secondary: `
      background: #2A2D36;
      box-shadow: 5px 5px 20px 0px #FCC85255, -5px -5px 20px 0px #FF3D9F55;

      &:hover {
        background: #191C25;
      }`,
};

const size = {
  small: `
    ${css.pad.s}
    font-size: 12px;
  `,
  medium: `
    min-width: 100px;
    height: 40px;
    font-size: 18px;
  `,
  large: `
    width: 300px;
    height: 150px;
    font-size: 24px;
  `,
};

const StlyedButton = styled.button<StyledButtonProps>`
  ${css.flex({ j: "c", aI: "c" })}
  ${css.pureText}
  ${css.point}

  ${({ theme }) => theme.borders.radiusNone};

  font-weight: 600;

  ${({ $variant }) => variant[$variant || "primary"]}

  ${({ $size }) => size[$size || "medium"]}
`;
