// npm
import { useCallback, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { AccountCircle } from "@mui/icons-material";

// hooks
import { useAppStore } from "store";
import { useUIStore } from "store/uiStore";

// css
import { css } from "css";

export const ProfileChip = () => {
  // hooks
  const { logout } = useAppStore();
  const { isMenuOpen, setIsMenuOpen, closeMenus, setIsBurgerMenuOpen } = useUIStore();

  // refs
  const chipRef = useRef<any>(null);

  // methods
  const toggleMenu = (e: any) => {
    e.stopPropagation();
    setIsMenuOpen("profile", !isMenuOpen["profile"]);
  };

  const handleClickOutside = useCallback(
    (event: any) => {
      if (chipRef.current && !chipRef.current.contains(event.target)) {
        closeMenus();
        setIsBurgerMenuOpen(false);
      }
    },
    [closeMenus, setIsBurgerMenuOpen],
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside]);

  return (
    <Wrapper>
      <StyledProfileChip onClick={toggleMenu}>
        <AccountCircle />
        {"username"}
      </StyledProfileChip>
      {isMenuOpen["profile"] && (
        <StyledMenu>
          <MenuItem
            onClick={() => {
              closeMenus();
              setIsBurgerMenuOpen(false);
            }}
          >
            <StyledLink to="/profile">Profile</StyledLink>
          </MenuItem>
          <MenuItem onClick={logout}>Logout</MenuItem>
        </StyledMenu>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  ${css.rel}
`;

const StyledProfileChip = styled.div`
  ${css.flex({ j: "fs", aI: "c", dir: "row", gap: 8 })}
  ${css.pad.s}
  ${css.pureText}
  ${css.point}

  ${({ theme }) => theme.borders.thin};

  width: 180px;
  height: 40px;
`;

const StyledMenu = styled.div`
  ${css.abs}
  ${css.flex({ j: "c", aI: "c", dir: "col", gap: 8 })}
  ${css.pad.s}
  ${css.z(1)}

  ${({ theme }) => theme.colors.gradientBG};
  ${({ theme }) => theme.borders.thin};

  top: 100%;
  left: 0;
  width: 180px;
`;

const MenuItem = styled.div`
  ${css.flex({ j: "c", aI: "c" })}
  ${css.pureText}
  ${css.textCenter}
  ${css.point}

  width: 100%;
  height: 35px;

  ${({ theme }) => theme.borders.radiusNone};

  &:hover {
    background-color: #101220;
  }
`;

const StyledLink = styled(Link)`
  ${css.flex({ j: "c", aI: "c" })}
  ${css.pureText}

  font-size: 16px;
  width: 100%;
  height: 100%;
`;
