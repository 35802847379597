// npm
import { useCallback, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import styled from "styled-components";
//import Language from "@mui/icons-material/Language";
import Home from "@mui/icons-material/Home";
import AutoStories from "@mui/icons-material/AutoStories";

// hooks
//import { useUILangs } from "hooks/uiLangs";
import { useUIStore } from "store/uiStore";
import { useAppStore } from "store";

// css
import { css } from "css";

type LanguageChipProps = {
  name: string;
};

export const LanguageChip = ({ name }: LanguageChipProps) => {
  // hooks
  const navigate = useNavigate();
  const location = useLocation();
  //const { langs } = useUILangs();
  const { isMenuOpen, setIsMenuOpen, isMobile, closeMenus, setIsBurgerMenuOpen } = useUIStore();
  const { targetLang, setTargetLang, homeLang, setHomeLang } = useAppStore();

  // refs
  const chipRef = useRef<any>(null);

  // methods
  const toggleMenu = (e: any) => {
    e.stopPropagation();
    setIsMenuOpen(name, !isMenuOpen[name]);
    setIsBurgerMenuOpen(false);
  };

  const handleClickOutside = useCallback(
    (event: any) => {
      if (chipRef.current && !chipRef.current.contains(event.target)) {
        closeMenus();
        setIsBurgerMenuOpen(false);
      }
    },
    [closeMenus, setIsBurgerMenuOpen],
  );

  const handleMenuItemClick = (code: string) => {
    if (name === "target") {
      setTargetLang(code);
    } else {
      setHomeLang(code);
    }
    closeMenus();
    navigate("/");
    if (location.pathname === "/") {
      navigate(0);
    }
  };

  // markup
  const displayLangSelector = () => {
    if (isMobile) return null;

    if (name === "target") return languages.find((lang) => lang.code === targetLang)?.name;
    else return languages.find((lang) => lang.code === homeLang)?.name;
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [handleClickOutside]);

  return (
    <Wrapper ref={chipRef}>
      <StyledLanguageChip onClick={toggleMenu}>
        {name === "target" && <AutoStories />}
        {name === "home" && <Home />}
        {displayLangSelector()}
      </StyledLanguageChip>
      {isMenuOpen[name] && (
        <StyledMenu>
          {languages.map((language) => {
            // don't display current language
            if (name === "target" && language.code === targetLang) return null;
            if (name === "home" && language.code === homeLang) return null;
            // we don't allow hungarian or korean as home language
            if (name === "home" && ["hu", "ko"].includes(language.code)) return null;

            return (
              <MenuItem key={language.code} onClick={() => handleMenuItemClick(language.code)}>
                {language.name}
              </MenuItem>
            );
          })}
        </StyledMenu>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  ${css.rel}
`;

const StyledLanguageChip = styled.div`
  ${css.flex({ j: "c", aI: "c", gap: 8 })}
  ${css.pad.s}
  ${css.pureText}
  ${css.point}

  ${({ theme }) => theme.borders.thin}

  height: 40px;
`;

const StyledMenu = styled.div`
  ${css.abs}
  ${css.flex({ j: "c", aI: "c", dir: "col" })}
  ${css.pad.s}
  ${css.z(2)}

  ${({ theme }) => theme.colors.gradientBG};
  ${({ theme }) => theme.borders.thin}

  top: 100%;
  right: 0;
  width: 180px;
`;

const MenuItem = styled.div`
  ${css.pad.s}
  ${css.pureText}
  ${css.point}
  ${css.textCenter}

  width: 100%;
  height: 35px;

  ${({ theme }) => theme.borders.radiusNone};

  &:hover {
    background-color: #101220;
  }
`;

// data
const languages = [
  {
    code: "en",
    name: "English",
  },
  {
    code: "ru",
    name: "Russian",
  },
  {
    code: "de",
    name: "German",
  },
  {
    code: "fr",
    name: "French",
  },
  {
    code: "es",
    name: "Spanish",
  },
  {
    code: "ko",
    name: "Korean",
  },
  {
    code: "it",
    name: "Italian",
  },
  {
    code: "hu",
    name: "Hungarian",
  },
];
