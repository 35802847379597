type FlexProps = {
  j?: "c" | "sb" | "se" | "fs" | "fe";
  aI?: "c" | "fs" | "fe";
  aC?: string;
  dir?: "col" | "row";
  wrap?: 1 | 0;
  gap?: number;
};

const jus = {
  c: "center",
  sb: "space-between",
  se: "space-evenly",
  fs: "flex-start",
  fe: "flex-end",
};

const alI = {
  c: "center",
  fs: "flex-start",
  fe: "flex-end",
};

const direc = {
  col: "column",
  row: "row",
};

const pad = {
  xs: "padding: 4px;",
  s: "padding: 8px;",
  m: "padding: 16px;",
  l: "padding: 24px;",
  xl: "padding: 32px;",
  xxl: "padding: 40px;",
};

const padX = {
  xs: "padding-left: 4px; padding-right: 4px;",
  s: "padding-left: 8px; padding-right: 8px;",
  m: "padding-left: 16px; padding-right: 16px;",
  l: "padding-left: 24px; padding-right: 24px;",
  xl: "padding-left: 32px; padding-right: 32px;",
  xxl: "padding-left: 40px; padding-right: 40px;",
};

const padY = {
  xs: "padding-top: 4px; padding-bottom: 4px;",
  s: "padding-top: 8px; padding-bottom: 8px;",
  m: "padding-top: 16px; padding-bottom: 16px;",
  l: "padding-top: 24px; padding-bottom: 24px;",
  xl: "padding-top: 32px; padding-bottom: 32px;",
  xxl: "padding-top: 40px; padding-bottom: 40px;",
};

export const css = {
  abs: "position: absolute;",
  rel: "position: relative;",
  fix: "position: fixed;",
  flex: ({ j, aI, aC, dir, wrap, gap }: FlexProps) => {
    return `
      display: flex;
      ${dir ? `flex-direction: ${direc[dir]};` : ""}
      ${j ? `justify-content: ${jus[j]};` : ""}
      ${aI ? `align-items: ${alI[aI]};` : ""}
      ${aC ? `align-content: ${aC};` : ""}
      ${wrap ? `flexWrap: ${wrap};` : ""}
      ${gap ? `gap: ${gap}px;` : ""}
    `;
  },
  point: "cursor: pointer;",
  pad,
  padX,
  padY,
  hide: {
    oF: "overflow: hidden;",
  },
  dropShadow: "box-shadow: 0px 4px 10px 10px rgba(0, 0, 0, 0.25);",
  pureText: "text-decoration: none; color: inherit; user-select: none;",
  z: (z: number) => `z-index: ${z};`,
  textCenter: "text-align: center;",
  textLeft: "text-align: left;",
  copy: "font-weight: 600; line-height: 1.5;",
  errorGlow: `border: 2px solid #ea282855;
          box-shadow:
            -2px -2px 5px 1px #ea282855,
            2px 2px 5px 1px #ea282855;`,
  scroll: `
    &::-webkit-scrollbar {
        width: 10px;
      }

      &::-webkit-scrollbar-thumb {
        background: linear-gradient(90deg, #fcc852, #ff3d9f);
        border-radius: 16px;
      };`,
};
