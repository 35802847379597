import { colors } from "./color";

export const borders = (theme: string) => {
  return {
    thin: `border: 1px solid ${colors[theme].lining};
           border-radius: 8px;`,
    md: `border: 2px solid #757575;
        border-radius: 6px;`,
    radiusNone: `border: none;
                 border-radius: 8px`,
  };
};
